import React from 'react'
import { Intent } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useUiState } from 'hooks/useUiState'
import toaster from 'lib/toaster'
import LoginPageView from '../../ui/user-auth/LoginPageView'

import { loginUserAction } from './userAuthSlice'

// -------------------------
// form logic

const initialValues = {
  email: '',
  password: '',
  remember: false,
}

function useHandleLoginSubmit() {
  const [trainingUrl] = useUiState('trainingUrl')
  const url = trainingUrl || '/'
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(loginUserAction(values.email, values.password))
      history.push(url)
    } catch (e) {
      toaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const LoginPage = () => {
  const handleLoginSubmit = useHandleLoginSubmit()
  return (
    <LoginPageView initialValues={initialValues} onSubmit={handleLoginSubmit} />
  )
}

export default LoginPage
