import UploadFileAudio from '../upload-file/UploadFileAudio'
import SidebarBlock from '../sidebar/SidebarBlock'
import AssociatedNode from '../fields/AssociatedNode'
import HideLabels from './HideLabels'
import PropTypes from 'prop-types'
import { stepProps } from 'lib/customPropTypes'
import { Step } from 'domain/step'
import PropertiesSidebarHeader from './PropertiesSidebarHeader'

const AnnotationOverviewSidebar = ({ step, lang = '' }) => {
  if (!step) return null

  return (
    <>
      <PropertiesSidebarHeader className="divider">
        All annotations
      </PropertiesSidebarHeader>
      <SidebarBlock
        label="Intro Audio"
        tooltipContent="Upload a voice-over for an introduction which plays automatically at start."
        className="divider"
      >
        <UploadFileAudio
          stepId={step.id}
          audioPath={Step.getAudioFileName(step, lang)}
          lang={lang}
        />
      </SidebarBlock>
      <SidebarBlock
        label="Step Selector"
        className="divider"
        tooltipContent="Selecting a step changes the base model used for annotations and is needed when the static model does not show enough of what you want to show during the annotation overview."
      >
        {Step.hasAssociatedNode(step) && (
          <AssociatedNode
            stepId={step.id}
            node={Step.getAssociatedNode(step)}
          />
        )}
      </SidebarBlock>
      <SidebarBlock
        label="General Settings"
        tooltipContent="These settings apply to the behavior of all annotations."
      >
        <HideLabels stepId={step.id} value={Step.getHideLabels(step)} />
      </SidebarBlock>
    </>
  )
}

AnnotationOverviewSidebar.propTypes = {
  stepsRemaining: PropTypes.number,
  step: stepProps,
  lang: PropTypes.string,
}

export default AnnotationOverviewSidebar
