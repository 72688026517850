import { ContentConfig } from './contentConfig'

import { get, set, castArray, update } from 'lodash'
import { Step } from './step'
import { Annotation } from './annotation'
import { randomID } from 'utils/id'

export class Training {
  static updateContentConfig(trainingData, updateFn) {
    return {
      ...trainingData,
      config: updateFn(trainingData.config),
    }
  }

  static updateTrainingThumbnail(trainingData, newThumbnailURL) {
    return {
      ...trainingData,
      training: { ...trainingData.training, thumbnail: newThumbnailURL },
    }
  }

  static updateSteps(trainingData, updateFn) {
    return {
      ...trainingData,
      steps: updateFn(trainingData.steps),
    }
  }

  static updateStepProp(trainingData, stepId, propName, value) {
    const updateFn = s => set(s, [...castArray(propName)], value)
    const listUpdateFn = steps => {
      return steps.map(s => (s.id === stepId ? updateFn(s) : s))
    }
    if (Training.hasExploration(trainingData)) {
      if (Training.isExplorationStep(trainingData, stepId)) {
        return Training.updateExplorationStep(trainingData, updateFn)
      } else {
        const id = Training.getAnnotationContainingStep(trainingData, stepId).id
        return Training.updateAnnotationSteps(trainingData, id, listUpdateFn)
      }
    } else {
      return Training.updateSteps(trainingData, listUpdateFn)
    }
  }

  static isExplorationStep(trainingData, stepId) {
    return trainingData.exploration.explorationStep.id === stepId
  }

  // Exploration property updates

  static getAnnotationPath(trainingData, annotationId) {
    const index = trainingData.exploration.annotations.findIndex(
      a => a.id === annotationId,
    )
    return ['exploration', 'annotations', index]
  }

  static getAnnotationContainingStep(trainingData, stepId) {
    if (!trainingData.exploration) return undefined
    return trainingData.exploration.annotations.find(
      a => !!a.steps.find(s => s.id === stepId),
    )
  }
  // Generic step updates

  static removeStep(trainingData, stepId) {
    const updateFn = steps => {
      const index = steps.findIndex(s => s.id === stepId)
      const result = [...steps]
      result.splice(index, 1)
      return result
    }
    if (Training.hasExploration(trainingData)) {
      const aid = Training.getAnnotationContainingStep(trainingData, stepId).id
      return Training.updateAnnotationSteps(trainingData, aid, updateFn)
    } else {
      return Training.updateSteps(trainingData, updateFn)
    }
  }

  // static addStep(trainingData, step, stepIdx) {
  //   return Training.updateSteps(trainingData, steps => {
  //     const result = [...steps]
  //     result.splice(stepIdx, 0, step)
  //     return result
  //   })
  // }

  static addNewStep(trainingData, id, cameraPose, associatedNode = undefined) {
    const contentConfig = Training.getContentConfig(trainingData)
    cameraPose = cameraPose || ContentConfig.getInitialCameraPose(contentConfig)
    return {
      ...trainingData,
      steps: [
        ...trainingData.steps,
        Step.createEmptyStep(id, cameraPose, associatedNode),
      ],
    }
  }

  static duplicateStep(trainingData, stepIdx) {
    // return Training.updateSteps(trainingData, steps => {
    //   const result = [...steps]
    //   result.splice(stepIdx, 0, { ...steps[stepIdx] })
    //   return result
    // })
  }

  static setSteps(trainingData, steps) {
    return { ...trainingData, steps }
  }

  // @TODO
  // static switchStepPosition(trainingData, sourceIdx, targetIdx) {
  //   let steps = [...trainingData.steps]
  //   let movingStep = steps.splice(sourceIdx, 1)[0]
  //   steps.splice(targetIdx, 0, movingStep)
  //   return {
  //     ...trainingData,
  //     steps: steps,
  //   }
  // }

  static getSteps(trainingData) {
    return trainingData.steps
  }

  // returns next, previous or undefined. In that order.
  static getNextOrPreviousStep(trainingData, stepId) {
    const steps = Training.hasExploration(trainingData)
      ? Training.getAnnotationContainingStep(trainingData, stepId).steps
      : Training.getSteps(trainingData)
    const index = steps.findIndex(s => s.id === stepId)
    if (steps[index + 1]) return steps[index + 1]?.id
    if (steps[index - 1]) return steps[index - 1]?.id
  }

  static getDetails(trainingData) {
    return trainingData.training
  }

  static getId(trainingData) {
    return trainingData.id
  }

  static getContentConfig(trainingData) {
    return trainingData.config
  }

  static getExploration(trainingData) {
    return trainingData.exploration
  }

  static removeInstructionsLang(trainingData, lang) {
    return Training.updateSteps(trainingData, steps => {
      return steps.map(s => Step.removeInstructionLang(s, lang))
    })
  }

  static hasOnlyOneLanguage(trainingData) {
    return ContentConfig.hasOnlyOneLanguage(
      Training.getContentConfig(trainingData),
    )
  }

  static removeAvailableLanguage(trainingData, lang) {
    if (Training.hasOnlyOneLanguage(trainingData)) {
      return trainingData
    } else {
      const updatedTrainingData = Training.updateContentConfig(
        trainingData,
        cc => ContentConfig.removeAvailableLanguage(cc, lang),
      )
      return Training.removeInstructionsLang(updatedTrainingData, lang)
    }
  }

  static addAvailableLanguage(trainingData, lang) {
    const contentConfig = Training.getContentConfig(trainingData)
    const firstLanguage = ContentConfig.getLanguages(contentConfig)[0]
    let result = Training.updateContentConfig(trainingData, cc =>
      ContentConfig.addAvailableLanguage(cc, lang),
    )
    result = Training.updateSteps(result, steps => {
      return steps.map(s => {
        return Step.addInstructionLang(
          s,
          lang,
          Step.getInstructionText(s, firstLanguage),
        )
      })
    })
    return result
  }

  static hasExploration(training) {
    return !!training.exploration
  }

  static getInstructionSteps(trainingData) {
    return get(trainingData, 'steps', [])
  }

  static getAnnotations(trainingData) {
    return get(trainingData, ['exploration', 'annotations'], [])
  }

  static getExplorationStep(trainingData) {
    return get(trainingData, ['exploration', 'explorationStep'])
  }

  static getEntityById(training, id) {
    if (Training.hasExploration(training)) {
      const { annotations, explorationStep } = Training.getExploration(training)
      const steps = annotations.reduce((acc, a) => acc.concat(a.steps), [])
      const candidates = [explorationStep, ...steps, ...annotations]
      return candidates.find(c => c.id === id)
    } else {
      return Training.getSteps(training).find(s => s.id === id)
    }
  }

  // Exploration updates

  static updateAnnotations(trainingData, updateFn) {
    return update({ ...trainingData }, ['exploration', 'annotations'], updateFn)
  }

  static updateExplorationStep(trainingData, updateFn) {
    return update(
      { ...trainingData },
      ['exploration', 'explorationStep'],
      updateFn,
    )
  }

  static updateAnnotationSteps(trainingData, annotationId, updateFn) {
    const path = Training.getAnnotationPath(trainingData, annotationId)
    return update({ ...trainingData }, [...path, 'steps'], updateFn)
  }

  static updateAnnotationLabel(trainingData, annotationId, label, lang) {
    const path = Training.getAnnotationPath(trainingData, annotationId)
    return set({ ...trainingData }, [...path, 'label', lang], label)
  }

  static updateAnnotationProp(trainingData, annotationId, propName, value) {
    const updateFn = a => set(a, [...castArray(propName)], value)
    return Training.updateAnnotations(trainingData, annotations =>
      annotations.map(a => (a.id === annotationId ? updateFn(a) : a)),
    )
  }

  static setAnnotations(trainingData, annotations) {
    return set({ ...trainingData }, ['exploration', 'annotations'], annotations)
  }

  static setAnnotationSteps(trainingData, annotationId, newSteps) {
    const updateFunc = () => {
      return newSteps
    }
    return Training.updateAnnotationSteps(
      trainingData,
      annotationId,
      updateFunc,
    )
  }

  static addNewStepToAnnotation(
    trainingData,
    annotationId,
    stepId,
    cameraPose,
    associatedNode = undefined,
  ) {
    const contentConfig = Training.getContentConfig(trainingData)
    cameraPose = cameraPose || ContentConfig.getInitialCameraPose(contentConfig)
    const path = Training.getAnnotationPath(trainingData, annotationId)
    const steps = get(trainingData, [...path, 'steps'])
    return set(
      trainingData,
      [...path, 'steps'],
      [...steps, Step.createEmptyStep(stepId, cameraPose, associatedNode)],
    )
  }

  static addNewAnnotation(trainingData, annotationId, position) {
    return update(
      { ...trainingData },
      ['exploration', 'annotations'],
      annotations => [
        ...annotations,
        Annotation.createAnnotation(annotationId, position),
      ],
    )
  }

  static removeAnnotation(trainingData, annotationId) {
    return Training.updateAnnotations(trainingData, annotations =>
      annotations.filter(a => a.id !== annotationId),
    )
  }

  static changeToExplorationMode(
    trainingData,
    annotationId,
    annotationPosition,
    cameraPose,
    associatedNode,
  ) {
    const steps = Training.getSteps(trainingData)
    return {
      ...trainingData,
      steps: [],
      exploration: {
        explorationStep: Step.createExplorationStep(
          randomID(),
          cameraPose,
          associatedNode,
        ),
        annotations: [
          Annotation.createAnnotation(annotationId, annotationPosition, steps),
        ],
      },
    }
  }

  static hasThumbnailsMissing(trainingData) {
    let missing = false
    Training.traverseSteps(trainingData, s => {
      if (!s.payload.thumbnail) missing = true
    })
    return missing
  }

  static traverseSteps(trainingData, fn) {
    if (Training.hasExploration(trainingData)) {
      const annotations = Training.getAnnotations(trainingData)
      fn(Training.getExplorationStep(trainingData))
      annotations.forEach(a => a.steps.forEach(fn))
    } else {
      trainingData.steps.forEach(fn)
    }
  }
}
