import React from 'react'
import { Spinner, Icon } from '@blueprintjs/core'

const SaveStatusDisplay = ({ text, saving, icon, intent = 'primary' }) => {
  return (
    <div className="save-status-display">
      <span className="save-status-text">{text}</span>
      {saving ? <Spinner intent="primary" size={16} /> : <Icon icon={icon} />}
    </div>
  )
}

export default SaveStatusDisplay
