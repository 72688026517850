import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@blueprintjs/core'
import { cameraPoseProps } from 'lib/customPropTypes'
import PropTypes from 'prop-types'
import { useCreateStep } from 'hooks/useCreateStep'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { getAnnotations } from 'features/contentEditor/contentEditorSlice'
import {
  getRenderedStep,
  getTempCameraPose,
  setRenderedStep,
} from 'features/uiState/uiStateSlice'
import { Step } from 'domain/step'
import { Tooltip2 } from '@blueprintjs/popover2'
import { getEntityById } from 'features/contentEditor/contentEditorSlice'
import useThumbnails from 'hooks/useThumbnails'

const AddStepButton = ({ explorationMode, disabled }) => {
  const dispatch = useDispatch()
  const [setThumbnail] = useThumbnails()
  const [activeSelection, setActiveSelection] = useActiveSelection()
  const createStep = useCreateStep()

  const renderedStepId = useSelector(getRenderedStep)
  const renderedStep = useSelector(getEntityById(renderedStepId))
  const annotations = useSelector(getAnnotations)
  const tempCameraPose = useSelector(getTempCameraPose(renderedStepId))
  const associatedNode = Step.getAssociatedNode(renderedStep)

  const handleClick = useCallback(() => {
    let annotationId
    let cameraPose = tempCameraPose
    if (explorationMode) {
      if (activeSelection.type === 'step') {
        annotationId = activeSelection.parentId || annotations.slice(-1)[0].id
      } else {
        annotationId = activeSelection.id
        cameraPose = Step.getCameraPose(renderedStep)
      }
    }

    const stepId = createStep(cameraPose, associatedNode, annotationId)
    // We have to manually update the rendered step and parentId because
    // setActiveSelection has an outdated version of training once we create the step
    setActiveSelection({ type: 'step', id: stepId, parentId: annotationId })
    dispatch(setRenderedStep(stepId))
    setThumbnail(stepId)
  }, [dispatch, tempCameraPose, associatedNode, annotations])

  return (
    <div className="addStep-button">
      <Button
        className="button"
        large
        icon="add"
        fill
        onClick={handleClick}
        text="Add Step"
        disabled={disabled}
      />
      {disabled ? (
        <Tooltip2
          className="tooltip"
          content="Please add an annotation first"
          placement="top"
          usePortal={false}
        >
          <span className="disabled-help"></span>
        </Tooltip2>
      ) : null}
    </div>
  )
}

AddStepButton.propTypes = {
  cameraPose: cameraPoseProps,
  associatedNode: PropTypes.string,
}

export default AddStepButton
