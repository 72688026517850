import PropTypes from 'prop-types'
import StepRow from './StepRow'
import AnnotationRow from './AnnotationRow'
import { stepProps } from 'lib/customPropTypes'

const StepTable = ({ instruction, selectedLanguage }) => {
  let rows = []
  const annotations = instruction.annotations || []
  rows = rows.concat(
    instruction.steps.map((s, i) => (
      <StepRow key={`step-intro-${i}`} step={s} lang={selectedLanguage} />
    )),
  )

  annotations.forEach((annotation, index) => {
    rows.push(
      <AnnotationRow
        key={`annotation-${index}`}
        annotation={annotation}
        label={annotation.label[selectedLanguage]}
        lang={selectedLanguage}
      />,
    )

    rows = rows.concat(
      annotation.steps.map((s, i) => (
        <StepRow key={`step-${index}-${i}`} step={s} lang={selectedLanguage} />
      )),
    )
  })

  return (
    <div className="langEditor-tableWrapper">
      <div className="langEditor-table">
        <div className="langEditor-tableHeader">
          <div className="langEditor-cell is-type"></div>
          <div className="langEditor-cell is-title">Title</div>
          <div className="langEditor-cell is-description">Description</div>
          <div className="langEditor-cell is-audio">Voice</div>
        </div>
        <div className="langEditor-tableBody">{rows}</div>
      </div>
    </div>
  )
}

StepTable.propTypes = {
  selectedLanguage: PropTypes.string,
  instruction: PropTypes.shape({
    steps: PropTypes.arrayOf(stepProps),
    annotations: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.objectOf(PropTypes.string),
        steps: PropTypes.arrayOf(stepProps),
      }),
    ),
  }),
}

export default StepTable
