import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPlaybackState,
  setPlaybackState,
} from 'features/uiState/uiStateSlice'
import usePlayer from '../features/contentEditor/player/hooks/usePlayer'

export function usePlaybackControl() {
  const dispatch = useDispatch()
  const playbackState = useSelector(getPlaybackState)
  const player = usePlayer()
  const _setPlaybackState = useCallback(
    v => {
      dispatch(setPlaybackState(v))
      if (v === 'paused') player.freeze()
      else if (v === 'play') player.unfreeze()
    },
    [dispatch],
  )
  return [playbackState, _setPlaybackState]
}
