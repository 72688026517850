import StepCard from 'ui/components/thumbs-sidebar/StepCard'
import { useEffect, useState } from 'react'
import AddStepButton from './AddStepButton'
import { getTempCameraPose } from 'features/uiState/uiStateSlice'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Step } from 'domain/step'
import { stepProps } from 'lib/customPropTypes'
import ThumbsSidebar from '../thumbs-sidebar/ThumbsSidebar'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { DragDropContext } from 'react-beautiful-dnd'
import DroppableStepsContainer from './DroppableStepsContainer'
import { setSteps } from 'features/contentEditor/contentEditorSlice'
import { useDispatch } from 'react-redux'
import AnnotationModeAlert from 'ui/components/alerts/AnnotationModeAlert'

const StepSidebar = ({ steps }) => {
  const [activeSelection, setActiveSelection] = useActiveSelection()
  const [ongoingDrag, setOngoingDrag] = useState(false)
  const stepId = activeSelection.id
  const tempCameraPose = useSelector(getTempCameraPose(stepId))
  const activeStep = steps.find(s => s.id === stepId)
  const activeAssociatedNode = Step.getAssociatedNode(activeStep) // @FIXME we need full step
  const dispatch = useDispatch()

  useEffect(() => {
    if (!activeSelection.id && steps.length > 0) {
      setActiveSelection('default')
    }
  }, [steps, activeSelection])

  // @TODO Antonio: add disabled step logic - toaster and thumbnail. I've added a
  // Toast: <CustomToast icon='warning-sign' isBig text='This step is disabled. You’ve uploaded a new model where the selected node cannot be found. Choose another node and you will activate this step again.' />
  // I've also added the prop isDisabled -> adds opacity and a disabled icon in StepCard

  function handleOnDragEnd(result) {
    // @TODO JUANLU: la nueva steplist no tenemos que guardarla en un estado local
    // sino hacer un setSteps en el slice
    setOngoingDrag(false)
    if (!result.destination) return
    const list = [...steps]
    const [movedStep] = list.splice(result.source.index, 1)
    list.splice(result.destination.index, 0, movedStep)
    dispatch(setSteps(list))
    setActiveSelection({ ...activeSelection })
  }

  function onDragStart() {
    setOngoingDrag(true)
  }

  return (
    <ThumbsSidebar>
      <div
        className={`thumbs-sidebar-main ${
          ongoingDrag ? 'prevent-smooth-scroll' : ''
        }`}
      >
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={onDragStart}>
          <DroppableStepsContainer steps={steps}></DroppableStepsContainer>
        </DragDropContext>
        <p className="sidebar-hint">
          Double click into your model to add an annotation
        </p>
      </div>

      <AddStepButton />

      <AnnotationModeAlert
        cameraPose={tempCameraPose}
        associatedNode={activeAssociatedNode}
      />
    </ThumbsSidebar>
  )
}

StepCard.propTypes = {
  steps: PropTypes.arrayOf(stepProps),
  isLoading: PropTypes.bool,
}

export default StepSidebar
