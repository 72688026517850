import PropTypes from 'prop-types'
import { InputGroup } from '@blueprintjs/core'

const LangEditorRow = ({ isStep, titleField, children }) => {
  return (
    <div className="langEditor-row">
      <div className="langEditor-cell is-type">
        <h5 className="langEditor-label">{isStep ? 'Step' : 'Annotation'}</h5>
      </div>
      <div className="langEditor-cell is-title">
        <InputGroup
          placeholder="Empty Title"
          value={titleField.value}
          onChange={titleField.onChange}
          onKeyPress={titleField.onKeyPress}
          onBlur={titleField.onBlur}
        />
      </div>
      {children}
    </div>
  )
}

LangEditorRow.propTypes = {
  isStep: PropTypes.bool,
  children: PropTypes.node,
}

export default LangEditorRow
