import { addNewAnnotation } from 'features/contentEditor/contentEditorSlice'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { randomID } from 'utils/id'

export const useCreateAnnotation = () => {
  const dispatch = useDispatch()

  const createAnnotation = useCallback(
    position => {
      const annotationId = randomID()
      dispatch(
        addNewAnnotation({
          annotationId,
          position,
        }),
      )
      return annotationId
    },
    [dispatch],
  )

  return createAnnotation
}
