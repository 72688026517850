import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

export const useInputUpdater = (
  originalValue = '',
  actionCreator,
  ms = 300,
) => {
  const [value, setValue] = useState(originalValue)
  const dispatch = useDispatch()
  const timeoutId = useRef()

  // commit the value to redux
  const publishValue = value => {
    clearTimeout(timeoutId.current)
    dispatch(actionCreator(value))
  }

  // update the input value and debouce to redux
  const updateValue = value => {
    setValue(value)
    if (ms === 0) {
      publishValue(value)
    } else {
      clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => publishValue(value), ms)
    }
  }

  // reflect changes in original value (from redux, probably)
  useEffect(() => {
    if (!isEqual(originalValue, value)) {
      setValue(originalValue)
    }
  }, [originalValue])

  return {
    value,
    onChange: e => updateValue(e.target.value),
    onValueChange: v => updateValue(v),
    onKeyPress: e => e.charCode === 13 && publishValue(value),
    onBlur: _e => publishValue(value),
  }
}
