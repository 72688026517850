import { currentAuthenticatedUserId } from 'lib/aucta-backend/auth'
import { userContentBucket } from '../config/aws-exports'
import { fileSystemOptions as options } from './options'
import {
  retrieveRawFile as _retrieveRawFile,
  retrieveJson as _retrieveJson,
  persistFile as _persistFile,
  persistJson as _persistJson,
  removeFile as _removeFile,
  uploadFile as _uploadFile,
  getTempLink as _getTempLink,
} from './index'

// @TODO read this from env

export async function userFilePath(path) {
  if (options.userPathProvider) return await options.userPathProvider(path)
  const userId = await currentAuthenticatedUserId()
  if (path.startsWith(userId)) {
    return path
  } else {
    return `${userId}/${path}`
  }
}

export async function retrieveRawFile(filePath, bucket) {
  return _retrieveRawFile(await userFilePath(filePath), userContentBucket)
}

export async function retrieveJson(filePath, bucket) {
  return _retrieveJson(await userFilePath(filePath), userContentBucket)
}

export async function persistFile(filePath, body) {
  return _persistFile(await userFilePath(filePath), body, userContentBucket)
}

export async function persistJson(filePath, data) {
  return _persistJson(await userFilePath(filePath), data, userContentBucket)
}

export async function updateJson(filePath, handler) {
  const content = await retrieveJson(filePath)
  const updated = handler(content)
  await persistJson(filePath, updated)
}

export async function removeFile(filePath) {
  return await _removeFile(await userFilePath(filePath), userContentBucket)
}

export async function uploadFile(file, filePath, progressHandler) {
  return _uploadFile(file, await userFilePath(filePath), userContentBucket)
}

export async function getTempLink(filePath) {
  return _getTempLink(
    await userFilePath(filePath),
    undefined,
    userContentBucket,
  )
}
