import { Training } from 'domain/training'
import { useActiveSelection } from 'hooks/useActiveSelection'
import PropTypes from 'prop-types'
import { stepProps } from '../../../lib/customPropTypes'
import StepPropertiesSidebar from './StepPropertiesSidebar'
import AnnotationPropertiesSidebar from './AnnotationPropertiesSidebar'
import AnnotationOverviewSidebar from './AnnotationOverviewSidebar'
import { Step } from 'domain/step'
import PropertiesSidebarWrapper from './PropertiesSidebarWrapper'

const PropertiesSidebar = ({ training, availableLanguages, lang = '' }) => {
  const [activeSelection] = useActiveSelection()
  const activeEntity = Training.getEntityById(training, activeSelection.id)

  //@TODO remove this when annotation removal is fixed
  if (!activeEntity) return null

  return (
    <PropertiesSidebarWrapper>
      {activeSelection.type === 'step' &&
        Step.getType(activeEntity) !== 'exploration' && (
          <StepPropertiesSidebar
            key={activeEntity.id} // this is needed for remount
            availableLanguages={availableLanguages}
            canDelete={
              Training.hasExploration(training) ||
              Training.getSteps(training).length > 1
            }
            step={activeEntity}
            lang={lang}
          />
        )}

      {activeSelection.type === 'step' &&
        Step.getType(activeEntity) === 'exploration' && (
          <AnnotationOverviewSidebar step={activeEntity} lang={lang} />
        )}

      {activeSelection.type === 'annotation' && (
        <AnnotationPropertiesSidebar annotation={activeEntity} lang={lang} />
      )}
    </PropertiesSidebarWrapper>
  )
}

PropertiesSidebar.propTypes = {
  steps: PropTypes.arrayOf(stepProps),
  stepIdx: PropTypes.number,
  lang: PropTypes.string,
}

export default PropertiesSidebar
