import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Tooltip2 } from '@blueprintjs/popover2'
import LangSelector from './LangSelector'

const SidebarBlock = ({
  label,
  children,
  className,
  tooltipContent,
  availableLanguages,
  langSelector,
  onChange,
}) => {
  return (
    <div className={cx('sidebar-block', { [className]: className })}>
      {label && (
        <div className={cx('sidebar-label', { 'has-select': langSelector })}>
          {label}
          {tooltipContent && (
            <Tooltip2
              className="tooltip-question"
              content={tooltipContent}
              placement="top"
              usePortal={false}
            >
              <span className="question">?</span>
            </Tooltip2>
          )}
          {langSelector && availableLanguages ? (
            <LangSelector availableLanguages={availableLanguages} />
          ) : null}
        </div>
      )}

      {children}
    </div>
  )
}

SidebarBlock.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default SidebarBlock
