import React, { useCallback } from 'react'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import Select from '../form-elements/Select'
import { useUiState } from 'hooks/useUiState'
import { Callout } from '@blueprintjs/core'
import useThumbnails from 'hooks/useThumbnails'
import { useDispatch } from 'react-redux'

const AssociatedNode = ({ stepId, node }) => {
  const [setThumbnail] = useThumbnails()
  const [stepNodes] = useUiState('model-step-nodes', [])
  const missingNode = node && !stepNodes.includes(node)
  const dispatch = useDispatch()

  const onChange = useCallback(ev => {
    dispatch(updateStepProp(stepId, ['payload', 'node'], ev.target.value, true))
    setThumbnail(stepId)
  })

  const options = [
    { label: '- select node -', value: null },
    ...stepNodes.map(n => ({ label: n, value: n })),
  ]

  if (missingNode)
    options.unshift({
      label: node,
      value: node,
    })

  return (
    <Select
      options={options}
      value={node}
      onChange={onChange}
      hasWarning={!!missingNode}
      textError={
        missingNode ? (
          <Callout icon="warning-sign" intent="warning">
            Your last uploaded model does not have this node anymore, but we'll
            leave it in here in case you want to reupload.
          </Callout>
        ) : (
          ''
        )
      }
    />
  )
}

export default AssociatedNode
