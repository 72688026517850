import React from 'react'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import TooltipCheck from '../tooltips/TooltipCheck'
import { useDispatch } from 'react-redux'

const PlayOnlyOnce = ({ stepId, value }) => {
  const dispatch = useDispatch()
  const playOnlyOnce = useInputUpdater(
    value,
    v => updateStepProp(stepId, ['payload', 'playOnlyOnce'], v),
    0,
  )

  return (
    <TooltipCheck
      checked={playOnlyOnce.value}
      checkLabel={'Play only once'}
      tooltipContent={'The animation will only play once.'}
      onChange={e => {
        playOnlyOnce.onValueChange(e.target.checked)
        if (!e.target.checked)
          dispatch(
            updateStepProp(stepId, ['payload', 'transient'], e.target.checked),
          )
      }}
      largeSwitch={true}
    />
  )
}

export default PlayOnlyOnce
