import { createSlice } from '@reduxjs/toolkit'
import { getTraining } from 'features/contentEditor/contentEditorSlice'
import { Training } from 'domain/training'
import { ContentConfig } from 'domain/contentConfig'

// -------------------------
// constants

const SELECTED_LANGUAGE = 'selected-language'
const ACTIVE_PAGE = 'active-page'
const ACTIVE_STEP = 'active-step'
const RENDERED_STEP = 'rendered-step'
const ACTIVE_SELECTION = 'active-selection'
const PLAYBACK_STATE = 'playback-state'
const STEP_REFRESH_REQUIRED = 'step-refresh-required'
const SAVE_STATE = 'save-state'
const MUTED = 'muted'
const TEMP_CAMERA_POSES = 'temp-camera-poses'
const CAMERA_POSE = 'camera-pose'
const LOADING = 'loading'
const ANNOTATION_REQUESTED = 'annotation-requested'
const REPOSITIONING_ANNOTATION = 'repositioning-annotation'
const IMPROPER_MODEL_STRUCTURE = 'improper-model-structure'
const PHONE_PREVIEW_ENABLED = 'preview-enabled'

export const MODEL_PAGE = 'model-settings-page'
export const STEPS_PAGE = 'steps-page'
export const LANGUAGES_PAGE = 'languages-page'

// -------------------------
// state

const initialState = {
  [ACTIVE_PAGE]: MODEL_PAGE,
  [ACTIVE_STEP]: null,
  [RENDERED_STEP]: null,
  [STEP_REFRESH_REQUIRED]: null,
  [ACTIVE_SELECTION]: { type: null, id: null },
  [PLAYBACK_STATE]: 'play',
  [SAVE_STATE]: 'none',
  [MUTED]: true,
  [TEMP_CAMERA_POSES]: {},
  [CAMERA_POSE]: {},
  [LOADING]: false,
  [ANNOTATION_REQUESTED]: null,
  [REPOSITIONING_ANNOTATION]: null,
  [IMPROPER_MODEL_STRUCTURE]: false,
  [PHONE_PREVIEW_ENABLED]: false,
}

const uiState = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload
      state[key] = value
    },
    unsetIfEqual(state, action) {
      const { key, value } = action.payload
      if (state[key] === value) {
        delete state[key]
      }
    },
    // custom actions
    setTempCameraPose(state, action) {
      const { stepId, pose } = action.payload
      // @TODO: review if this works correctly (inner ref)
      state[TEMP_CAMERA_POSES] = {
        ...state[TEMP_CAMERA_POSES],
        [stepId]: pose,
      }
    },
    requireStepRefresh(state, action) {
      state[STEP_REFRESH_REQUIRED] = Math.random()
    },
  },
})

export default uiState

// -------------------------
// selectors

export const get = key => state => state.uiState[key]

export const getSelectedLanguage = state => {
  // this logic has to be somewhere, and this looks like a convenient place
  const training = getTraining(state)
  const contentConfig = Training.getContentConfig(training)
  const languages = ContentConfig.getLanguages(contentConfig)
  return get(SELECTED_LANGUAGE)(state) || languages[0]
}

export const getActivePage = state => {
  return get(ACTIVE_PAGE)(state)
}

export const getActiveStep = state => {
  return get(ACTIVE_STEP)(state)
}

export const getRenderedStep = state => {
  return get(RENDERED_STEP)(state)
}

export const getActiveSelection = state => {
  return get(ACTIVE_SELECTION)(state)
}

export const getPlaybackState = state => {
  return get(PLAYBACK_STATE)(state)
}

export const getSaveState = state => {
  return get(SAVE_STATE)(state)
}

export const getMuted = state => {
  return get(MUTED)(state)
}

export const getCameraPose = state => {
  return get(CAMERA_POSE)(state)
}

export const getTempCameraPose = stepId => state => {
  const pose = get(TEMP_CAMERA_POSES)(state)[stepId]
  return pose
}

export const getPhonePreviewEnabled = state => {
  return get(PHONE_PREVIEW_ENABLED)(state)
}

// -------------------------
// actions

export const set = (key, value) => {
  return uiState.actions.set({ key, value })
}

export const setSelectedLanguage = value => {
  return set(SELECTED_LANGUAGE, value)
}

export const unselectLanguage = value => {
  return uiState.actions.unsetIfEqual({ key: SELECTED_LANGUAGE, value })
}

export const setActivePage = page => {
  return set(ACTIVE_PAGE, page)
}

export const setActiveStep = stepIdx => {
  return set(ACTIVE_STEP, stepIdx)
}

export const setRenderedStep = stepId => {
  return set(RENDERED_STEP, stepId)
}

export const setActiveSelection = selection => {
  return set(ACTIVE_SELECTION, selection)
}

export const setPlaybackState = playbackState => {
  return set(PLAYBACK_STATE, playbackState)
}

export const setSaveState = saveState => {
  return set(SAVE_STATE, saveState)
}

export const setMuted = muted => {
  return set(MUTED, muted)
}

export const setCameraPose = pose => {
  return set(CAMERA_POSE, pose)
}

export const setTempCameraPose = (stepId, pose) => {
  return uiState.actions.setTempCameraPose({ stepId, pose })
}

export const requireStepRefresh = () => {
  return uiState.actions.requireStepRefresh()
}

export const setPhonePreviewEnabled = value => {
  return set(PHONE_PREVIEW_ENABLED, value)
}
