import React from 'react'
import ModelSidebar from 'ui/components/model-sidebar/ModelSidebar'
import StepSidebar from 'ui/components/thumbs-sidebar/StepSidebar'
import AnnotationSidebar from 'ui/components/thumbs-sidebar/AnnotationSidebar'
import PropertiesSidebar from 'ui/components/properties-sidebar/PropertiesSidebar'
import NavigationSidebar from 'ui/components/navigation-sidebar/NavigationSidebar'
import PropTypes from 'prop-types'
import { stepProps, trainingProps } from 'lib/customPropTypes'
import { Training } from 'domain/training'
import {
  MODEL_PAGE,
  STEPS_PAGE,
  LANGUAGES_PAGE,
} from 'features/uiState/uiStateSlice'

import Player from 'features/contentEditor/player/Player'
import LanguageEditorPage from 'features/contentEditor/LanguageEditorPage'
import cx from 'classnames'
import SaveStatusDisplay from 'ui/components/save-status/SaveStatusDisplay'
import MuteButton from 'ui/components/sidebar/MuteButton'
import SpinnerOverlay from 'ui/components/spinner/SpinnerOverlay'
import PreviewPlaybackControlButton from 'ui/components/sidebar/PreviewPlaybackControlButton'
import PhonePreviewButton from 'features/phonePreview/PhonePreviewButton'

const ContentEditorPageView = ({
  availableLanguages,
  selectedLanguage,
  training,
  activePage,
  steps,
  exploration,
  loading,
  saveState,
  phonePreview,
}) => {
  return (
    <div
      id="content-editor"
      className={cx('contentEditor-container', activePage)}
    >
      <NavigationSidebar trainingId={training?.id} isLoading={loading} />

      {activePage === MODEL_PAGE && (
        <ModelSidebar
          training={training}
          contentConfig={Training.getContentConfig(training)}
          isLoading={loading}
        />
      )}

      {activePage === STEPS_PAGE && exploration && (
        <AnnotationSidebar
          selectedLanguage={selectedLanguage}
          training={training}
          isLoading={loading}
        />
      )}

      {activePage === STEPS_PAGE && !exploration && (
        <StepSidebar steps={steps} isLoading={loading} />
      )}

      <div className="contentEditor-content">
        <MuteButton />
        <PreviewPlaybackControlButton />
        <PhonePreviewButton />
        {activePage === LANGUAGES_PAGE && (
          <LanguageEditorPage
            availableLanguages={availableLanguages}
            selectedLanguage={selectedLanguage}
            training={training}
          />
        )}

        {process.env.STORYBOOK ? (
          <div className="storybook-player-preview">Player Preview</div>
        ) : (
          <>
            {loading ? <SpinnerOverlay /> : null}
            <Player
              forceLoading={loading}
              lang={selectedLanguage}
              phonePreview={phonePreview}
            ></Player>
          </>
        )}
        <div id="ui-explorer-dots"></div>
        <div className="save-status-container">
          {saveState === 'saving' ? (
            <SaveStatusDisplay saving text="Saving" />
          ) : saveState === 'saved' ? (
            <SaveStatusDisplay text="Saved" icon="tick" />
          ) : saveState === 'error' ? (
            <SaveStatusDisplay
              text="Error saving"
              icon="cross"
              intent="danger"
            />
          ) : (
            ''
          )}
        </div>
      </div>

      {activePage === STEPS_PAGE && (
        <PropertiesSidebar
          training={training}
          availableLanguages={availableLanguages}
          lang={selectedLanguage}
        />
      )}
    </div>
  )
}

ContentEditorPageView.propTypes = {
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
  selectedLanguage: PropTypes.string,
  training: trainingProps,
  activeStep: PropTypes.number,
  activePage: PropTypes.string,
  steps: PropTypes.arrayOf(stepProps),
  //@TODO: annotationData
  loading: PropTypes.bool,
  saveState: PropTypes.string,
}

export default ContentEditorPageView
