import { useDispatch } from 'react-redux'
import { updateConfigOption } from 'features/contentEditor/contentEditorSlice'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import useThumbnails from './useThumbnails'

export const useUpdateConfigOption = () => {
  const dispatch = useDispatch()
  const player = usePlayer()
  const [, refreshThumbnails] = useThumbnails()

  return async (optionName, value) => {
    await dispatch(updateConfigOption(optionName, value))
    await player.refreshModel()
    refreshThumbnails()
  }
}
