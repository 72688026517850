import { useCallback, useMemo } from 'react'
import Select from '../form-elements/Select'
import {
  setSelectedLanguage,
  getSelectedLanguage,
  requireStepRefresh,
} from 'features/uiState/uiStateSlice'
import { batch, useDispatch, useSelector } from 'react-redux'

export const LangSelector = ({ availableLanguages }) => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const onChange = useCallback(
    ev => {
      batch(() => {
        dispatch(setSelectedLanguage(ev.target.value))
        dispatch(requireStepRefresh())
      })
    },
    [dispatch],
  )

  const languageOptions = useMemo(() => {
    return availableLanguages.map(l => ({
      label: l.toUpperCase(),
      value: l,
    }))
  }, [availableLanguages])

  return (
    <Select
      className="inline-select"
      options={languageOptions}
      value={selectedLanguage}
      onChange={onChange}
    />
  )
}

export default LangSelector
