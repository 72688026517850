export function simplifyTree(tree) {
  return tree.map((node, i) => ({
    name: node.name,
    children: simplifyTree(node.children),
  }))
}

export function findNodeInTree(tree, node) {
  let result
  traverseTree(tree, n => {
    if (n.label === node.label) result = n
  })
  return result
}

export function traverseTree(tree, func) {
  for (let node of tree) {
    traverseChildren(node, func)
  }
}

export function traverseChildren(node, func) {
  if (node.children.length > 0) {
    func(node)
    traverseTree(node.children, func)
  } else {
    func(node)
  }
}

export function applyExpandedState(tree, expandedNodes) {
  return tree.map((node, i) => ({
    ...node,
    expanded: !!expandedNodes.find(n => node.label === n.label),
    children: applyExpandedState(node.children, expandedNodes),
  }))
}

export function nodeTreeFormatDropdown(tree, selectedNodes) {
  return tree.map(node => {
    if (node === undefined) return undefined
    return {
      label: node.name,
      checked: selectedNodes.includes(node.name) || false,
      children: nodeTreeFormatDropdown(node.children, selectedNodes),
      disabled: false,
    }
  })
}

export function disableTopLevel(tree) {
  return tree.map(n => ({ ...n, disabled: true }))
}
