import React from 'react'
import PropTypes from 'prop-types'
import InfoPiece from './InfoPiece'
import cx from 'classnames'

const InfoPieceGroup = ({ items = [], captionGroup }) => {
  return (
    <div className={cx('infoPiece-group', { 'caption-group': captionGroup })}>
      {items.map(item => (
        <InfoPiece
          key={item.label}
          value={item.value}
          label={item.label}
          caption={captionGroup}
        />
      ))}
    </div>
  )
}

InfoPieceGroup.propTypes = {
  captionGroup: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
}

export default InfoPieceGroup
