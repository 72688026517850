import { makePathRelativeToTraining } from 'api/trainings'
import { setModelFile } from 'features/contentEditor/contentEditorSlice'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTraining } from './useTraining'
import { get } from 'lodash'
import { useUiState } from './useUiState'
import useThumbnails from 'hooks/useThumbnails'

export function useModel() {
  const dispatch = useDispatch()
  const player = usePlayer()
  const training = useTraining()
  const [, setStepNodes] = useUiState('model-step-nodes')
  const modelUrl = get(training, 'config.model', null)
  const [, setUiLoading] = useUiState('loading')
  const [, refreshThumbnails] = useThumbnails()

  const setModel = useCallback(
    async (modelName, modelPath) => {
      setUiLoading(true)
      const modelUrl = makePathRelativeToTraining(modelPath)
      await player.loadModel(modelUrl)
      await setStepNodes(player.getModelInstructionNodes())
      dispatch(setModelFile(modelName, modelPath))
      setUiLoading(false)
      window.requestAnimationFrame(() => {
        refreshThumbnails()
      })
    },
    [dispatch, refreshThumbnails],
  )
  return [modelUrl, setModel]
}
