import React from 'react'
import { Intent } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { recoverPasswordAction } from 'features/userAuth/userAuthSlice'
import AppToaster from 'lib/toaster'
import PasswordRecoveryPageView from 'ui/user-auth/PasswordRecoveryPageView'

// -------------------------
// form logic

const initialValues = {
  email: '',
}

function useHandleSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(recoverPasswordAction(values.email))
      AppToaster.show({
        icon: 'envelope',
        intent: Intent.PRIMARY,
        message: 'Email with reset code sent.',
      })
      history.push('/auth/password-reset')
    } catch (e) {
      AppToaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const PasswordRecoveryPage = () => {
  const handleSubmit = useHandleSubmit()
  return (
    <PasswordRecoveryPageView
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  )
}

export default PasswordRecoveryPage
