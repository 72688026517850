import React from 'react'
import { FileInput, Icon } from '@blueprintjs/core'
import cx from 'classnames'

const UploadForm = ({ text, onFileSelected, accept, className }) => {
  const inputProps = {}
  if (accept) inputProps.accept = accept
  return (
    <div
      className={cx('uploadFile', {
        [className]: className,
      })}
    >
      <FileInput
        text={text}
        fill
        inputProps={inputProps}
        onInputChange={e => {
          const file = e.target.files[0]
          if (file) onFileSelected(file)
        }}
      />
      <Icon icon="inbox-search" className="browse-icon" />
    </div>
  )
}

export default UploadForm
