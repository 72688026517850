import { uploadTrainingFile } from 'api/storage'
import {
  updateStepProp,
  setTrainingThumbnail,
} from 'features/contentEditor/contentEditorSlice'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useTraining } from './useTraining'
import { audioContentServer } from 'config/audio'
import { idToIdx } from 'features/contentEditor/utils'
import { useActiveSelection } from './useActiveSelection'
import { getTempCameraPose } from 'features/uiState/uiStateSlice'
import { useCallback } from 'react'
import { Training } from 'domain/training'

const ThumbnailSize = { width: 300, height: 200 }

function srcUrl(src) {
  if (src.startsWith('http')) {
    return src
  } else {
    return `${audioContentServer}/${src}`
  }
}

const urlToImage = async (url, fileName) => {
  const res = await fetch(url)
  const buffer = await res.arrayBuffer()
  return new File([buffer], fileName, { type: 'png' })
}

export default function useThumbnails() {
  const training = useTraining()
  const player = usePlayer()
  const dispatch = useDispatch()
  const [activeSelection, setActiveSelection] = useActiveSelection()
  // @TODO: Having any component using useThumbnails refresh everytime the camera changes
  // is pretty expensive...
  const tempPose = useSelector(getTempCameraPose(activeSelection.id))

  const sendToTrainingThumbnail = src => {
    dispatch(setTrainingThumbnail(srcUrl(src)))
  }

  const setThumbnail = useCallback(
    id =>
      batch(async () => {
        const currentlySelected = id === activeSelection.id
        const fileName = `step_${id}_thumbnail.png`
        if (currentlySelected) player.showStillFrame()
        await player.preloadStep(idToIdx(training, id))
        const url = player.getThumbnail(
          ThumbnailSize.width,
          ThumbnailSize.height,
        )
        if (currentlySelected) {
          setActiveSelection({
            ...activeSelection,
            cameraOverride: tempPose,
            instantCameraOverride: true,
          })
        }
        const image = await urlToImage(url, fileName)
        if (currentlySelected) player.removeStillFrame()
        const upload = await uploadTrainingFile(image, training.id)
        dispatch(
          updateStepProp(
            id,
            ['payload', 'thumbnail'],
            srcUrl(upload.Key),
            true,
          ),
        )
        if (idToIdx(training, id) <= 0) sendToTrainingThumbnail(upload.Key)
      }),
    [player, tempPose, training],
  )

  const refreshThumbnails = () =>
    batch(async () => {
      const stepsToUpdate = []
      Training.traverseSteps(training, step => {
        if (player.checkStepNodeExists(step.payload.node))
          stepsToUpdate.push(step)
      })
      const thumbnails = await player.getAllThumbnails(
        stepsToUpdate,
        ThumbnailSize.width,
        ThumbnailSize.height,
      )
      thumbnails.forEach(async thumbnail => {
        const fileName = `step_${thumbnail.id}_thumbnail.png`
        const image = await urlToImage(thumbnail.url, fileName)
        const upload = await uploadTrainingFile(image, training.id)
        dispatch(
          updateStepProp(
            thumbnail.id,
            ['payload', 'thumbnail'],
            srcUrl(upload.Key),
            true,
          ),
        )
        if (idToIdx(training, thumbnail.id) === 0)
          sendToTrainingThumbnail(upload.Key)
      })
    })

  return [setThumbnail, refreshThumbnails]
}
