import { get, without } from 'lodash'

export class ContentConfig {
  // @TODO: missing prop: initial camera target
  // @TODO: missing prop: initial camera position
  // @TODO: missing prop: VR content scale
  // @TODO: missing feature: envMap

  static configOptions = [
    {
      name: 'disableBackfaceCulling',
      label: 'Disable Backface Culling',
      tooltip: 'Polygon faces will be visible in both directions.',
    },
    {
      name: 'fixMaterialMetalness',
      label: 'Fix material metalness',
      tooltip: 'Remove shininess from model surfaces.',
    },
    {
      name: 'sRGBEncoding',
      label: 'sRGB Encoding',
      tooltip: 'The material colors are sRGB enconded.',
    },
    {
      name: 'gammaEncoding',
      label: 'Gamma Encoding',
      tooltip: 'The material colors are gamma encoded.',
    },
  ]

  static getConfigOptions(contentConfig) {
    return ContentConfig.configOptions.map(option => ({
      ...option,
      value: contentConfig[option.name],
    }))
  }

  static setConfigOption(contentConfig, optionName, optionValue) {
    return { ...contentConfig, [optionName]: optionValue }
  }

  static setContentScale(contentConfig, scale) {
    return { ...contentConfig, contentScale: [scale, scale, scale] }
  }

  static getContentScale(contentConfig) {
    return get(contentConfig, 'contentScale.0', 0)
  }

  static hasAutoScale(contentConfig) {
    return contentConfig.autoScale !== undefined
      ? contentConfig.autoScale
      : !contentConfig.contentScale
  }

  static setInitialCameraPose(contentConfig, cameraPose) {
    const { camera, target } = cameraPose
    return {
      ...contentConfig,
      initialCameraPosition: [camera.x, camera.y, camera.z],
      initialCameraTarget: [target.x, target.y, target.z],
    }
  }

  static getInitialCameraPose(contentConfig) {
    const camera = get(contentConfig, 'initialCameraPosition', [0, 0, -3])
    const target = get(contentConfig, 'initialCameraTarget', [0, 0, 0])
    return {
      camera: { x: camera[0], y: camera[1], z: camera[2] },
      target: { x: target[0], y: target[1], z: target[2] },
    }
  }

  static getLanguages(contentConfig) {
    return contentConfig.languages || []
  }

  static removeAvailableLanguage(contentConfig, lang) {
    return {
      ...contentConfig,
      languages: without(contentConfig.languages, lang),
    }
  }

  static addAvailableLanguage(contentConfig, lang) {
    if (contentConfig.languages.includes(lang)) {
      console.log('* contentConfig already includes', lang)
      return contentConfig
    } else {
      return { ...contentConfig, languages: [...contentConfig.languages, lang] }
    }
  }

  static hasOnlyOneLanguage(contentConfig) {
    return contentConfig.languages.length <= 1
  }

  static getModel(contentConfig) {
    return contentConfig.model
  }

  static getModelName(contentConfig) {
    return contentConfig.modelName || contentConfig.model
  }

  static setModel(contentConfig, model) {
    return { ...contentConfig, model }
  }

  static setModelName(contentConfig, modelName) {
    return { ...contentConfig, modelName }
  }

  // constants

  static AUTO_SCALE = 'autoScale'
  static CONTENT_SCALE = 'contentScale'
  static INITIAL_STATE = { autoScale: true }
}
