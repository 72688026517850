import { useState, useEffect, useCallback } from 'react'
import { uploadTrainingFile } from 'api/storage'
import { trainingPath } from 'api/trainings'
import { useSelector } from 'react-redux'
import { getTraining } from 'features/contentEditor/contentEditorSlice'
import { isEmpty } from 'lodash'

export const modes = {
  EMPTY: 'EMPTY',
  READY: 'READY',
  PROGRESS: 'PROGRESS',
  UPLOAD: 'UPLOAD',
}

export const useFileUploader = ({
  fileName,
  onFinish,
  onStart,
  onReset,
  onDelete,
  onError,
}) => {
  const [mode, setMode] = useState(modes.EMPTY)
  const [progress, setProgress] = useState(0)
  const training = useSelector(getTraining)

  // update the mode if we got model after render
  useEffect(() => {
    if (mode === modes.EMPTY && !isEmpty(fileName)) {
      setMode(modes.READY)
    } else if (isEmpty(fileName)) {
      setMode(modes.EMPTY)
    }
  }, [fileName])

  const handleUpload = useCallback(
    async file => {
      if (!training.id) throw new Error('Invalid training!')
      setMode(modes.PROGRESS)
      setProgress(0)
      onStart && onStart()
      try {
        const res = await uploadTrainingFile(file, training.id, setProgress)
        onFinish && onFinish(file.name, res.Key)
        console.log(`* ${file.name} -> ${res.Key}`)
        setMode(modes.READY)
      } catch (e) {
        onError(e)
      }
    },
    [setMode, setProgress, onFinish, training],
  )

  const handleReset = useCallback(() => {
    onReset && onReset()
    setMode(modes.UPLOAD)
  }, [setMode, onReset])

  const handleDelete = useCallback(() => {
    onDelete && onDelete()
    setMode(modes.UPLOAD)
  }, [setMode, onDelete])

  return { handleUpload, handleReset, handleDelete, mode, progress }
}
