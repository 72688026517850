import { useMemo } from 'react'
import LanguageEditorPageView from 'ui/language-editor/LanguageEditorPageView'
import { Training } from 'domain/training'

function aggregateStepsAndAnnotations(training) {
  const exploration = Training.getExploration(training)
  if (exploration) {
    return {
      steps: [exploration.explorationStep],
      annotations: exploration.annotations,
    }
  } else {
    return {
      steps: Training.getSteps(training),
      annotations: [],
    }
  }
}

const LanguageEditorPage = ({
  availableLanguages,
  selectedLanguage,
  training,
}) => {
  const instruction = useMemo(() => aggregateStepsAndAnnotations(training), [
    training,
  ])

  return (
    <LanguageEditorPageView
      availableLanguages={availableLanguages}
      selectedLanguage={selectedLanguage}
      instruction={instruction}
    />
  )
}

export default LanguageEditorPage
