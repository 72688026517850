import PropTypes from 'prop-types'
import { useActivePage } from 'hooks/useActivePage'
import { managerTrainingUrl } from 'config/aucta'
import {
  MODEL_PAGE,
  STEPS_PAGE,
  LANGUAGES_PAGE,
} from 'features/uiState/uiStateSlice'
import { Icon } from '@blueprintjs/core'
import { ReactComponent as Aucta } from '../../../assets/icons/icon-aucta.svg'
import { ReactComponent as Steps } from '../../../assets/icons/icon-steps.svg'
import cx from 'classnames'

const NavigationSidebar = ({ trainingId, isLoading }) => {
  const [activePage, setActivePage] = useActivePage()

  return (
    <div className="navigation-sidebar">
      <a
        className={cx('nav-item', { disabled: isLoading })}
        href={`${managerTrainingUrl}/${trainingId}`}
      >
        <Aucta className="icon" />
      </a>
      <button
        className={cx('nav-item', {
          disabled: isLoading,
          active: activePage === MODEL_PAGE,
        })}
        onClick={() => setActivePage(MODEL_PAGE)}
      >
        <Icon icon="cube" className="nav-item-icon" />
        <span className="nav-item-text">Model</span>
      </button>
      <button
        className={cx('nav-item', {
          disabled: isLoading,
          active: activePage === LANGUAGES_PAGE,
        })}
        onClick={() => setActivePage(LANGUAGES_PAGE)}
      >
        <Icon icon="translate" className="nav-item-icon" />
        <span className="nav-item-text">Languages</span>
      </button>
      <button
        className={cx('nav-item', {
          disabled: isLoading,
          active: activePage === STEPS_PAGE,
        })}
        onClick={() => setActivePage(STEPS_PAGE)}
      >
        <Steps className="nav-item-icon" />
        <span className="nav-item-text">Steps</span>
      </button>
    </div>
  )
}

NavigationSidebar.propTypes = {
  isLoading: PropTypes.bool,
  trainingId: PropTypes.string,
}

export default NavigationSidebar
