import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedLanguage } from 'features/uiState/uiStateSlice'
import { languages as allLanguages } from 'lib/languages.js'
import { useRemoveLanguage } from 'hooks/availableLanguages'
import { get } from 'lodash'
import { Intent, H5, Alert } from '@blueprintjs/core'

import LanguageTab from './LanguageTab'
import AddLangWidget from 'ui/components/lang-widget/AddLangWidget'

const LanguageTabs = ({ availableLanguages, selectedLanguage }) => {
  const dispatch = useDispatch()
  const removeLanguage = useRemoveLanguage()
  const [langToDelete, setLangToDelete] = useState(false)
  const handleDeleteLang = () => {
    removeLanguage(langToDelete)
    setLangToDelete(false)
  }

  const tabs = availableLanguages.map(lang => (
    <LanguageTab
      key={`tab-${lang}`}
      text={`[${lang.toUpperCase()}] ${get(allLanguages, [lang, 'name'])}`}
      selected={lang === selectedLanguage}
      onSelect={() => dispatch(setSelectedLanguage(lang))}
      onDelete={() => setLangToDelete(lang)}
    />
  ))

  return (
    <div className="langEditor-tabsItems">
      <div className="langEditor-tabsList">{tabs}</div>
      <AddLangWidget
        availableLanguages={availableLanguages}
        selectedLanguage={selectedLanguage}
      />
      <Alert
        isOpen={!!langToDelete}
        icon="trash"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={() => setLangToDelete(false)}
        onConfirm={handleDeleteLang}
        className="custom-alert"
      >
        <H5>
          Are you sure you want to delete the language "
          {get(allLanguages, [langToDelete, 'name'])}"?
        </H5>
        <p>
          All steps and step data related to this language will be destroyed.
        </p>
      </Alert>
    </div>
  )
}

LanguageTabs.propTypes = {
  selectedLanguage: PropTypes.string,
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
}

export default LanguageTabs
