import TextInstruction from '../fields/TextInstruction'
import SidebarBlock from '../sidebar/SidebarBlock'
import StepType from '../fields/StepType'
import StepFeatures from '../fields/StepFeatures'
import UploadFileAudio from '../upload-file/UploadFileAudio'
import DeleteStepButton from 'ui/components/fields/DeleteStepButton'
import PropTypes from 'prop-types'
import { ANIMATION_STEP, Step } from 'domain/step'
import { stepProps } from 'lib/customPropTypes'
import PropertiesSidebarHeader from './PropertiesSidebarHeader'

const StepPropertiesSidebar = ({
  canDelete,
  step,
  availableLanguages,
  lang = '',
  onChange,
}) => {
  if (!step) return null

  return (
    <>
      <PropertiesSidebarHeader
        deleteSlot={canDelete && <DeleteStepButton step={step} />}
      >
        {step.title || 'Untitled step'}
      </PropertiesSidebarHeader>
      <SidebarBlock
        className="divider"
        label="Step Title and Description"
        langSelector
        availableLanguages={availableLanguages}
        onChange={onChange}
      >
        <TextInstruction
          stepId={step.id}
          lang={lang}
          text={Step.getInstructionText(step, lang)}
          title={Step.getTitle(step)}
        />
      </SidebarBlock>
      <SidebarBlock label="Audio" className="divider">
        <UploadFileAudio
          stepId={step.id}
          audioPath={Step.getAudioFileName(step, lang)}
          lang={lang}
        />
      </SidebarBlock>
      <SidebarBlock
        label="Step Type"
        className="divider"
        tooltipContent="Animated step types are created in the AUCTA web editor. Blender step types are created in Blender and imported through our GLTF compatible markup language."
      >
        <StepType stepId={step.id} step={step} />
      </SidebarBlock>

      {Step.getType(step) === ANIMATION_STEP && (
        <SidebarBlock label="Features">
          <StepFeatures step={step} />
        </SidebarBlock>
      )}
    </>
  )
}

StepPropertiesSidebar.propTypes = {
  stepsRemaining: PropTypes.number,
  step: stepProps,
  lang: PropTypes.string,
}

export default StepPropertiesSidebar
