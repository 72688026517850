import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ContentEditorPageView from '../../ui/content-editor/ContentEditorPageView'
import { Training } from 'domain/training'
import { ContentConfig } from 'domain/contentConfig'
import { useUiState } from 'hooks/useUiState'
import { idToIdx } from 'features/contentEditor/utils'

import { fetchTraining, getTraining } from './contentEditorSlice'
import { usePublishTraining } from 'hooks/usePublishTraining'
import { get as getUIState } from 'features/uiState/uiStateSlice.js'
import { useActivePage } from 'hooks/useActivePage'
import usePlayer from './player/hooks/usePlayer'
import { TrainingNotFoundError } from 'api/trainings'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { env as environment } from 'lib/aucta-backend/env'
import { isEmpty } from 'lodash-es'
import { usePhonePreview } from 'hooks/usePhonePreview'

/* eslint-disable react-hooks/exhaustive-deps */

// -------------------------
// hooks

function useFetchTraining() {
  const dispatch = useDispatch()
  return async id => dispatch(fetchTraining(id))
}

// -------------------------
// ui component

const ContentEditorWrapper = () => {
  // parm extraction
  const { id } = useParams()

  // api actions
  const history = useHistory()
  const fetchTraining = useFetchTraining()
  const publishTraining = usePublishTraining()
  const training = useSelector(getTraining)
  const player = usePlayer()
  const [loading, setLoading] = useState(false)
  const [activePage] = useActivePage()
  const [activeSelection] = useActiveSelection()
  const [uiLoading] = useUiState('loading')
  const [uiSaveState] = useUiState('saveState')
  const [stepRefreshRequired] = useUiState('step-refresh-required')
  const [, setStepNodes] = useUiState('model-step-nodes')
  const [phonePreview] = usePhonePreview()

  const firstRenderForTraining = useRef(true)
  const trainingHash = JSON.stringify(training)
  console.log('!> training', training)

  // Initial data fetching
  useEffect(() => {
    const doFetchTraining = async () => {
      setLoading(true)
      try {
        const training = await fetchTraining(id)
        await player.loadTraining(training)
        setStepNodes(player.getModelInstructionNodes())
        setLoading(false)
      } catch (err) {
        if (err instanceof TrainingNotFoundError) {
          history.push('/not-found')
        } else {
          throw err
        }
      }
    }
    doFetchTraining()
  }, [id])

  // Enable manual publishing in dev
  useEffect(() => {
    player.loadInstructions(training)
    if (environment.consolePublish)
      window['publish'] = () => publishTraining(training, true)
  }, [training])

  // Reload step if it was modified
  useEffect(() => {
    player.loadInstructions(training)
    player.refreshStep()
  }, [stepRefreshRequired])

  // Act on a new selection
  useEffect(() => {
    if (activeSelection.type === 'step') {
      const idx = idToIdx(training, activeSelection.id)
      player.setActiveStep(
        idx,
        true,
        activeSelection.cameraOverride,
        activeSelection.instantCameraOverride,
      )
    } else if (activeSelection.type === 'annotation') {
      player.preloadStep(0, true)
    }
  }, [activeSelection])

  // Autosave on training change
  useEffect(async () => {
    if (isEmpty(training.training)) return
    if (firstRenderForTraining.current === true) {
      firstRenderForTraining.current = false
      return
    }
    publishTraining(training)
  }, [trainingHash])

  // Data prepareation
  const contentConfig = Training.getContentConfig(training)
  const availableLanguages = ContentConfig.getLanguages(contentConfig)
  let selectedLanguage =
    useSelector(getUIState('selected-language')) || availableLanguages[0]
  const exploration = Training.getExploration(training)
  const steps = Training.getInstructionSteps(training, selectedLanguage)

  // Component
  return (
    <ContentEditorPageView
      availableLanguages={availableLanguages}
      selectedLanguage={selectedLanguage}
      training={training}
      steps={steps}
      exploration={exploration}
      activePage={activePage}
      loading={loading || uiLoading}
      saveState={uiSaveState}
      phonePreview={phonePreview}
    />
  )
}

export default ContentEditorWrapper
