import React from 'react'
import { Intent } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetPasswordAction } from 'features/userAuth/userAuthSlice'
import AppToaster from 'lib/toaster'
import PasswordResetPageView from 'ui/user-auth/PasswordResetPageView'

// -------------------------
// form logic

const initialValues = {
  email: '',
  code: '',
  password: '',
  'confirm-password': '',
}

function useHandleSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(
        resetPasswordAction(values.email, values.code, values.password),
      )
      AppToaster.show({
        icon: 'key',
        intent: Intent.SUCCESS,
        message: 'Password succesufully changed.',
      })
      history.push('/auth/login')
    } catch (e) {
      AppToaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const PasswordResetPage = () => {
  const handleSubmit = useHandleSubmit()
  return (
    <PasswordResetPageView
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  )
}

export default PasswordResetPage
