export class Annotation {
  // Getters
  static getId(annotation) {
    return annotation.id
  }

  static getLabel(annotation, lang) {
    return annotation.label[lang]
  }

  static getSteps(annotation) {
    return annotation.steps
  }

  static setSteps(annotation, steps) {
    return { ...annotation, steps }
  }

  // Getters
  static createAnnotation(annotationId, position, steps = []) {
    return {
      id: annotationId,
      position: position,
      label: {},
      steps: steps,
    }
  }
}
