import React from 'react'

const PhonePreviewFrame = () => {
  return (
    <div id="phone-preview-container" className="phone-preview-container">
      <div id="phone-preview-frame" className="phone-preview-frame"></div>
    </div>
  )
}
export default PhonePreviewFrame
