import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Button } from '@blueprintjs/core'
import Select from '../form-elements/Select'
import { languages as allLanguages } from 'lib/languages.js'
import { useAddLanguage } from 'hooks/availableLanguages'
import { isEmpty } from 'lodash'

const languagesAsOptions = Object.entries(allLanguages).map(([k, v]) => {
  return { value: k, label: v.name }
})

const AddLangWidget = ({ availableLanguages, selectedLanguage }) => {
  const addLanguage = useAddLanguage()
  const [selectValue, setSelectValue] = useState('')
  const [options, setOptions] = useState([])

  // recalculate available options when availableLanguages change
  useEffect(() => {
    setOptions([
      { value: '', label: 'Add new language' },
      ...languagesAsOptions.filter(
        ({ value }) => !availableLanguages.includes(value),
      ),
    ])
  }, [availableLanguages])

  return (
    <div className="langWidget">
      <div className="langWidget-add">
        <Select
          options={options}
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
        />
        <Button
          disabled={selectValue === ''}
          onClick={() => {
            if (!isEmpty(selectValue)) {
              addLanguage(selectValue)
              setSelectValue('')
            }
          }}
          icon="add"
        />
      </div>
    </div>
  )
}

AddLangWidget.propTypes = {
  selectedLanguage: PropTypes.string,
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
}

export default AddLangWidget
