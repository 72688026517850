import React from 'react'
import PropTypes from 'prop-types'
import { contentConfigProps } from 'lib/customPropTypes'
import SidebarBlock from '../sidebar/SidebarBlock'
import TooltipCheck from '../tooltips/TooltipCheck'
import { ContentConfig } from 'domain/contentConfig'
import { useUpdateConfigOption } from 'hooks/useUpdateConfigOption'

const ModelConfig = ({ contentConfig = {}, className }) => {
  const configOptions = ContentConfig.getConfigOptions(contentConfig)
  const updateConfigOption = useUpdateConfigOption()
  return (
    <SidebarBlock label="Model Configuration" className={className}>
      {configOptions.map(option => (
        <TooltipCheck
          checked={option.value}
          key={option.name}
          checkLabel={option.label}
          tooltipContent={option.tooltip}
          // @TODO: is this ok to emit from here? (for Sara, for Good Design, etc...)
          onChange={e => {
            updateConfigOption(option.name, e.target.checked)
          }}
        />
      ))}
    </SidebarBlock>
  )
}

ModelConfig.propTypes = {
  contentConfig: contentConfigProps,
  className: PropTypes.string,
}

export default ModelConfig
