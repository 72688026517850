import React from 'react'
import PropTypes from 'prop-types'
import { TextArea, InputGroup } from '@blueprintjs/core'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import { Step } from 'domain/step'
import { get } from 'lodash'

const TextInstruction = ({ stepId, lang, text, title }) => {
  const field = useInputUpdater(text, v =>
    updateStepProp(stepId, Step.getInstructionTextPath(lang), v),
  )
  // what happens when the input changes?
  const nameField = useInputUpdater(title, v =>
    updateStepProp(stepId, 'title', v),
  )
  const actualLength = get(field.value, 'length', 0)
  return (
    <>
      <InputGroup
        placeholder="Step Title"
        value={nameField.value}
        onChange={nameField.onChange}
        onKeyPress={nameField.onKeyPress}
        onBlur={nameField.onBlur}
      />
      <div className="textarea-wrapper">
        <TextArea
          fill
          placeholder="Text instruction here..."
          value={field.value}
          onChange={field.onChange}
          onKeyPress={field.onKeyPress}
          onBlur={field.onBlur}
          maxLength="100"
        />
        <div className="textarea-counter">{actualLength}/100</div>
      </div>
    </>
  )
}

TextInstruction.propTypes = {
  lang: PropTypes.string,
  stepId: PropTypes.string,
  text: PropTypes.string,
}

export default TextInstruction
