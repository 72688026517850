import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import FileInfo from './FileInfo'
import InfoPieceGroup from '../info-piece/InfoPieceGroup'
import { useFileUploader } from 'hooks/useFileUploader'
import UploadFile from './UploadFile'
import { getModelStepNodes } from 'utils/model'
import { useUiState } from 'hooks/useUiState'
import { Intent, H5, Alert } from '@blueprintjs/core'
import { useModel } from 'hooks/useModel'

const ModelFile = ({
  fileName,
  disabled = false,
  className,
  onBeforeUpload = undefined,
  onAfterUpload = undefined,
}) => {
  const [stepNodes] = useUiState('model-step-nodes', [])
  const [stepMissingData, setStepMissingData] = useState(undefined)
  const [improperStructureData, setImproperStructureData] = useState(undefined)
  const [, setModelFile] = useModel()
  const [, setUiLoading] = useUiState('loading')

  const onFinish = useCallback(
    async (modelName, modelPath) => {
      setUiLoading(true)
      const newNodes = await getModelStepNodes(modelPath)
      setUiLoading(false)
      if (!newNodes || newNodes.length === 0) {
        setImproperStructureData({
          modelName,
          modelPath,
        })
      } else {
        const missingSteps = stepNodes.filter(s => !newNodes.includes(s))
        const newSteps = newNodes.filter(s => !stepNodes.includes(s))
        if (missingSteps.length > 0) {
          setStepMissingData({
            missingSteps,
            newSteps,
            modelName,
            modelPath,
          })
        } else {
          setModelFile(modelName, modelPath)
          onAfterUpload && onAfterUpload()
        }
      }
    },
    [stepNodes],
  )

  const fileUploader = useFileUploader({
    fileName,
    onFinish,
    onStart: onBeforeUpload,
  })

  // @TODO: extract relevant details from the model
  // like: size, number of step animations, etc...
  const modelDetails = []

  return (
    <div className="uploadFile-wrapper">
      <UploadFile
        accept=".glb"
        placeholder={fileName || 'Choose model...'}
        fileUploader={fileUploader}
        offerReupload={true}
        className={className}
      >
        <FileInfo
          fileName={fileName}
          onReload={fileUploader.handleReset}
          bottomBar={<InfoPieceGroup captionGroup items={modelDetails} />}
        />
      </UploadFile>

      <Alert
        isOpen={!!stepMissingData}
        icon="cube"
        cancelButtonText="Revert Upload"
        confirmButtonText="Proceed"
        intent={Intent.PRIMARY}
        className="custom-alert"
        onCancel={() => {
          setStepMissingData(undefined)
          onAfterUpload && onAfterUpload()
        }}
        onConfirm={() => {
          setModelFile(stepMissingData.modelName, stepMissingData.modelPath)
          setStepMissingData(undefined)
          onAfterUpload && onAfterUpload()
        }}
      >
        <H5>The following step nodes could not be found</H5>
        {stepMissingData &&
          stepMissingData.missingSteps.map((s, i) => (
            <p className="step" key={i}>
              {s}
            </p>
          ))}

        {stepMissingData && stepMissingData.newSteps.length > 0 && (
          <>
            <p>The following step nodes are new:</p>
            {stepMissingData &&
              stepMissingData.newSteps.map((s, i) => (
                <p className="step" key={i}>
                  {s}
                </p>
              ))}
          </>
        )}

        <p>Do you want to proceed with the upload?</p>
      </Alert>

      <Alert
        isOpen={!!improperStructureData}
        icon="cube"
        cancelButtonText="Revert Upload"
        confirmButtonText="Proceed"
        intent={Intent.PRIMARY}
        className="custom-alert"
        onCancel={() => {
          setImproperStructureData(undefined)
          onAfterUpload && onAfterUpload()
        }}
        onConfirm={() => {
          setModelFile(
            improperStructureData.modelName,
            improperStructureData.modelPath,
          )
          setImproperStructureData(undefined)
          onAfterUpload && onAfterUpload(true)
        }}
      >
        <H5>The new 3D model has no steps</H5>
        <p>
          It seems that the file you are uploading has no steps (instructions).
          You can still create annotations with this file, but some functions
          will not be shown in the editor.
        </p>
      </Alert>
    </div>
  )
}

ModelFile.propTypes = {
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  forcedMode: UploadFile.propTypes.forcedMode,
  className: PropTypes.string,
  onBeforeUpload: PropTypes.func,
  onAfterUpload: PropTypes.func,
}

export default ModelFile
