import { useDispatch } from 'react-redux'
import {
  removeAvailableLanguage,
  addAvailableLanguage,
} from 'features/contentEditor/contentEditorSlice'
import { unselectLanguage } from 'features/uiState/uiStateSlice'

export const useRemoveLanguage = () => {
  const dispatch = useDispatch()
  return lang => {
    dispatch(removeAvailableLanguage(lang))
    dispatch(unselectLanguage(lang))
  }
}

export const useAddLanguage = () => {
  const dispatch = useDispatch()
  return lang => {
    dispatch(addAvailableLanguage(lang))
  }
}
