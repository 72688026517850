import React from 'react'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import TooltipCheck from '../tooltips/TooltipCheck'

const HideLabels = ({ stepId, value }) => {
  const updater = useInputUpdater(
    value,
    v => updateStepProp(stepId, ['payload', 'hideLabels'], v),
    0,
  )

  return (
    <TooltipCheck
      checked={updater.value}
      checkLabel={'Hide all labels'}
      tooltipContent={'The annotations will not show labels'}
      onChange={e => {
        updater.onValueChange(e.target.checked)
      }}
      largeSwitch={true}
    />
  )
}

export default HideLabels
