import React from 'react'
import cx from 'classnames'

const PropertiesSidebarHeader = ({ children, deleteSlot, className }) => {
  return (
    <div
      className={cx('properties-sidebar-header', { [className]: className })}
    >
      <div className="properties-sidebar-title">{children}</div>
      {deleteSlot ? deleteSlot : null}
    </div>
  )
}

export default PropertiesSidebarHeader
