import React, { useCallback } from 'react'
import { useMutedControl } from 'hooks/useMutedControl'
import ButtonIcon from '../buttons/ButtonIcon'

const MuteButton = () => {
  const [muted, setMuted] = useMutedControl()
  const toggleMuted = useCallback(() => setMuted(!muted), [muted, setMuted])
  return (
    <ButtonIcon
      icon={muted ? 'volume-off' : 'volume-up'}
      onClick={toggleMuted}
      text=""
      className="mute-button"
    />
  )
}

export default MuteButton
