import React from 'react'

import { Spinner } from '@blueprintjs/core'

const SpinnerOverlay = () => {
  return (
    <div className="spinner-overlay">
      <Spinner intent="primary" />
    </div>
  )
}

export default SpinnerOverlay
