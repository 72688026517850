import { usePhonePreview } from 'hooks/usePhonePreview'
import React, { useCallback } from 'react'
import TooltipCheck from '../../ui/components/tooltips/TooltipCheck'

const PhonePreviewButton = () => {
  const [enabled, setEnabled] = usePhonePreview()
  const toggleEnabled = useCallback(() => setEnabled(!enabled), [
    enabled,
    setEnabled,
  ])
  return (
    <div className="phone-preview-button">
      <TooltipCheck
        checked={enabled}
        checkLabel={'Phone preview'}
        tooltipContent={'Shows how this would look in a phone'}
        onChange={toggleEnabled}
        largeSwitch={false}
        isDisabled={false}
      />
    </div>
  )
}

export default PhonePreviewButton
