import { get, omit } from 'lodash'

export const SIMPLE_STEP = 'simple'
export const ANIMATION_STEP = 'animation'
export const TRANSIENT_STEP = 'transient'
export const EXPLORATION_STEP = 'exploration'
export const INTERACTIVE_STEP = 'interactive-tap'
// const EXPLORATION_STEP = 'exploration'

export class Step {
  // @TODO: remove this
  static stepTypes = [
    {
      label: 'Simple',
      value: SIMPLE_STEP,
    },
    {
      label: 'Animation',
      value: ANIMATION_STEP,
    },
    {
      label: 'Transient',
      value: TRANSIENT_STEP,
    },
    {
      label: 'Interactive',
      value: INTERACTIVE_STEP,
    },
  ]

  static selectableStepTypes = [
    {
      label: 'Animation',
      value: ANIMATION_STEP,
    },
    {
      label: 'Interactive',
      value: INTERACTIVE_STEP,
    },
  ]

  static createEmptyStep(stepId, cameraPose, node) {
    return {
      title: undefined,
      id: stepId,
      type: ANIMATION_STEP,
      payload: { ...cameraPose, highlights: [], animations: [], node },
      instructions: {},
    }
  }

  static createExplorationStep(stepId, cameraPose, node) {
    return {
      title: 'All Annotations',
      id: stepId,
      type: EXPLORATION_STEP,
      payload: { ...cameraPose, highlights: [], animations: [], node },
      instructions: {},
    }
  }

  static removeInstructionLang(stepData, lang) {
    return { ...stepData, instructions: omit(stepData.instructions, [lang]) }
  }

  static addInstructionLang(stepData, lang, text = '', audio = '') {
    return {
      ...stepData,
      instructions: {
        ...stepData.instructions,
        [lang]: { text, audio },
      },
    }
  }

  static getType(stepData) {
    return stepData.type
  }

  static getId(stepData) {
    return stepData.id
  }

  static getTitle(stepData) {
    return stepData.title
  }

  static getAssociatedNode(stepData) {
    return get(stepData, 'payload.node', '')
  }

  static getTriggerNode(stepData) {
    return get(stepData, 'payload.trigger')
  }

  static getCameraPosition(stepData) {
    return get(stepData, 'payload.camera', {})
  }

  static getCameraTarget(stepData) {
    return get(stepData, 'payload.target', {})
  }

  static getCameraPose(stepData) {
    return {
      camera: Step.getCameraPosition(stepData),
      target: Step.getCameraTarget(stepData),
    }
  }

  static getPlayOnlyOnce(stepData) {
    return get(stepData, 'payload.playOnlyOnce', false)
  }

  static getTransient(stepData) {
    return get(stepData, 'payload.transient', false)
  }

  static getHideLabels(stepData) {
    return get(stepData, 'payload.hideLabels', false)
  }

  static getAudioFileName(stepData, lang) {
    return get(stepData, Step.getAudioPropPath(lang), '')
  }

  static getAudioPropPath(lang) {
    return ['instructions', lang, 'audio']
  }

  static getInstructionText(stepData, lang) {
    return get(stepData, Step.getInstructionTextPath(lang), '')
  }

  static getInstructionTextPath(lang) {
    return ['instructions', lang, 'text']
  }

  static getAnnotationLabel(explorationStepData, annotationIdx, lang) {
    return get(
      explorationStepData,
      Step.getAnnotationLabelPath(annotationIdx, lang),
      '',
    )
  }

  static getAnnotationLabelPath(annotationIdx, lang) {
    return ['payload', 'hotspots', annotationIdx, 'label', lang]
  }

  // policies

  static hasAssociatedNode(stepData) {
    return [
      ANIMATION_STEP,
      TRANSIENT_STEP,
      INTERACTIVE_STEP,
      EXPLORATION_STEP,
    ].includes(stepData.type)
  }

  static hasTextInstruction(stepData) {
    return [SIMPLE_STEP, ANIMATION_STEP, INTERACTIVE_STEP].includes(
      stepData.type,
    )
  }

  static hasTriggerNode(stepData) {
    return [INTERACTIVE_STEP].includes(stepData.type)
  }
}
