import React from 'react'
import cx from 'classnames'

const ThumbsSidebar = ({ children, className }) => {
  return (
    <div
      className={cx('thumbs-sidebar', {
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

export default ThumbsSidebar
