import React, { useCallback } from 'react'
import { Icon } from '@blueprintjs/core'
import { usePlaybackControl } from 'hooks/usePlaybackControl'

const PreviewPlaybackControlButton = () => {
  const [playbackState, setPlaybackState] = usePlaybackControl()
  const paused = playbackState === 'paused'
  const togglePaused = useCallback(() => {
    setPlaybackState(paused ? 'play' : 'paused')
  }, [paused, setPlaybackState])
  return (
    <button onClick={togglePaused} className="button-icon play-pause-button">
      <Icon icon={paused ? 'play' : 'pause'} />
    </button>
  )
}

export default PreviewPlaybackControlButton
