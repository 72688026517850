// export async function initialize(forceRefresh = false) {
//   if (cloudFront === undefined || forceRefresh) {
//     const credentials = await currentCredentials()
//     AWS.config.credentials = credentials
//     AWS.config.region = awsConfig.region
//     cloudFront = new AWS.CloudFront({
//       apiVersion: '2020-05-31',
//       region: awsConfig.region,
//       params: { DistributionId: awsConfig.cloudFrontDistribution },
//       credentials: credentials,
//     })
//   }
// }

import { currentUserId } from 'lib/aucta-backend/auth'
import { env } from 'lib/aucta-backend/env'
import { createInvalidation as _createInvalidation } from 'lib/aucta-backend/cdn'

// // utils

// async function fullPath(path) {
//   const userId = await currentUserId()
//   return `/${userId}/${path}`
// }

async function userPath(path) {
  const userId = await currentUserId()
  return `/${userId}/${path}`
}

export async function createInvalidation(path) {
  await _createInvalidation(await userPath(path), env.userContentDistribution)

  // await initialize()
  // const invalidationPath = await fullPath(path)
  // console.log('...creating invalidation for:', invalidationPath)
  // return cloudFront
  //   .createInvalidation({
  //     InvalidationBatch: {
  //       CallerReference: uuid(),
  //       Paths: {
  //         Quantity: 1,
  //         Items: [invalidationPath],
  //       },
  //     },
  //   })
  //   .promise()
}
