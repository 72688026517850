import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Icon } from '@blueprintjs/core'

const Select = ({
  options,
  isDisabled,
  onBlur,
  onChange,
  textLabel,
  textError,
  value,
  name,
  hasWarning,
  className,
}) => {
  const classes = cx('select', {
    'is-disabled': isDisabled,
    'has-warning': hasWarning,
    [className]: className,
  })

  return (
    <div className={classes}>
      {textLabel ? <label className="select-label">{textLabel}</label> : null}
      <div className="select-box">
        <select
          className="select-select"
          id={textLabel}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          value={value}
        >
          {options.map(option => (
            <option value={option.value} key={option.label}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon icon="chevron-down" />
      </div>
      {textError ? <span className="errorText">{textError}</span> : null}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  textLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasWarning: PropTypes.bool,
}

export default Select
