import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { stepProps } from 'lib/customPropTypes'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNextOrPreviousStep,
  getTraining,
  removeStep,
  updateTraining,
} from 'features/contentEditor/contentEditorSlice'
import { Button, Alert, Intent, H5 } from '@blueprintjs/core'
import AppToaster, { undoToast } from 'lib/toaster'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { cloneDeep } from 'lodash'
import { getTempCameraPose } from 'features/uiState/uiStateSlice'

const DeleteStepButton = ({ step }) => {
  const dispatch = useDispatch()
  // ui state
  const [showModal, setShowModal] = useState(false)
  const showConfirmationModal = () => setShowModal(true)
  const dismissConfirmationModal = () => setShowModal(false)
  const nextStepId = useSelector(getNextOrPreviousStep(step.id))
  const [activeSelection, setActiveSelection] = useActiveSelection()
  const training = useSelector(getTraining)
  const tempPose = useSelector(getTempCameraPose(step.id))

  const handleDeleteStep = useCallback(() => {
    const snapshot = cloneDeep(training)
    dismissConfirmationModal()
    dispatch(removeStep(step.id))
    if (nextStepId) {
      setActiveSelection({ type: 'step', id: nextStepId })
    } else {
      setActiveSelection('default')
    }

    const toastText = `Step ${step.title || 'untitled step'} deleted`
    AppToaster.show(
      undoToast(toastText, () => {
        dispatch(updateTraining(snapshot))
        setActiveSelection({ ...activeSelection, cameraOverride: tempPose })
        // @TODO: this does not update the player camera, a new method is needed in player-lib
        // we said we'd wait to implement this once we decide how to deal with overlaping player async actions
        // dispatch(setTempCameraPose(stepIdx, tempPose))
      }),
    )
  }, [step, nextStepId, tempPose])

  return (
    <>
      <Button
        onClick={showConfirmationModal}
        icon="trash"
        className="button-icon"
      />

      <Alert
        isOpen={showModal}
        icon="trash"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={dismissConfirmationModal}
        onConfirm={handleDeleteStep}
        className="custom-alert"
      >
        <H5>Are you sure you want to delete this step?</H5>
        <p>This operation cannot be undone.</p>
      </Alert>
    </>
  )
}

DeleteStepButton.propTypes = {
  step: stepProps,
  stepIdx: PropTypes.number,
  stepsLength: PropTypes.number,
}

export default DeleteStepButton
