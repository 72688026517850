import { Step } from 'domain/step'
import StepCameraButton from './StepCameraButton'
import { stepProps } from '../../../lib/customPropTypes'
import PropTypes from 'prop-types'
import { usePlaybackControl } from 'hooks/usePlaybackControl'
import { useActiveSelection } from 'hooks/useActiveSelection'
import cx from 'classnames'
import CustomToast from 'ui/components/toasts/CustomToast'
import { ReactComponent as AnnotationIcon } from '../../../assets/icons/icon-annotation.svg'
import { useEffect, useRef } from 'react'
import { isElementInViewport } from 'utils/dom'
import useThumbnails from 'hooks/useThumbnails'
import defaultImage from '../../../assets/images/step-default-image.png'

const StepCard = ({
  step,
  isActive,
  missingNode = false,
  onCameraChange,
  isOverview,
}) => {
  const [, setActiveSelection] = useActiveSelection()
  const [, setPlaybackState] = usePlaybackControl()
  const [setThumbnail] = useThumbnails()
  const divRef = useRef(null)
  const thumbnail = step.payload.thumbnail
  const onClick = () => {
    setActiveSelection({
      type: 'step',
      id: step.id,
    })
    setPlaybackState('play')
  }

  useEffect(() => {
    if (isActive && !isElementInViewport(divRef.current)) {
      divRef.current.scrollIntoView()
    }
  }, [isActive])

  // @TODO we have to review this.
  useEffect(() => {
    if (!thumbnail && isOverview) {
      setThumbnail(step.id)
    }
  }, [thumbnail, step])

  return (
    <>
      <div
        ref={divRef}
        className={cx('sidebar-step', {
          active: isActive,
          disabled: missingNode,
          'is-overview': isOverview,
        })}
      >
        <div className="sidebar-step-title" onClick={onClick}>
          {isOverview ? <AnnotationIcon /> : null}
          {isOverview ? 'All annotations' : step.title || 'Untitled step'}
        </div>
        <div className="sidebar-step-thumb" onClick={onClick}>
          <div className="thumb-wrapper">
            <img
              src={thumbnail || defaultImage}
              alt={`step ${step.id}`}
              className="thumb-image"
            />
          </div>
        </div>
        {isActive && (
          <div className="sidebar-step-controls">
            <StepCameraButton
              stepId={step.id}
              camera={Step.getCameraPosition(step)}
              target={Step.getCameraTarget(step)}
              onCameraChange={onCameraChange}
            />
          </div>
        )}
        <div className="sidebar-step-gradient"></div>
      </div>

      {missingNode && isActive && (
        <CustomToast
          icon="warning-sign"
          isBig
          text="This step is disabled. You’ve uploaded a new model where the selected node cannot be found. Choose another node and you will activate this step again."
        />
      )}
    </>
  )
}

StepCard.propTypes = {
  step: stepProps,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onCameraChange: PropTypes.func,
  missingNode: PropTypes.bool,
}

export default StepCard
