import React from 'react'
import { NonIdealState } from '@blueprintjs/core'
import AppLayout from 'ui/layouts/AppLayout'
import { env } from 'lib/aucta-backend/env'

const NotFoundPageView = () => (
  <AppLayout>
    <div className="notFound-message">
      <NonIdealState
        icon="delete"
        title="Nothing to see here"
        description="The page you're trying to access is empty. If you're trying to edit a training, please use the link below to go to the Manager and try again."
        action={
          <a href={env.managerURL} style={{ cursor: 'pointer', color: 'blue' }}>
            Go back to the manager
          </a>
        }
      />
    </div>
  </AppLayout>
)

export default NotFoundPageView
