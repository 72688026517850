import React from 'react'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import TooltipCheck from '../tooltips/TooltipCheck'

const TransientToggle = ({ stepId, value, isDisabled }) => {
  const transient = useInputUpdater(
    value,
    v => updateStepProp(stepId, ['payload', 'transient'], v),
    0,
  )

  return (
    <TooltipCheck
      checked={transient.value}
      checkLabel={'Transient'}
      isDisabled={isDisabled}
      tooltipContent={
        'If you activate transient, the step will automatically progress forward once the animation has ended.'
      }
      onChange={e => {
        transient.onValueChange(e.target.checked)
      }}
      largeSwitch={true}
    />
  )
}

export default TransientToggle
