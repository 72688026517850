import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ContentEditorPage from 'features/contentEditor/ContentEditorPage'
import NotFoundPageView from '../ui/generic-pages/NotFoundPageView'
import LoginPage from 'features/userAuth/LoginPage'
import ProtectedContent from 'components/meta/ProtectedContent'
import PasswordRecoveryPage from 'features/userAuth/PasswordRecoveryPage'
import PasswordResetPage from 'features/userAuth/PaswordResetPage'

const App = () => (
  <Router>
    <Switch>
      {/* auth */}
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/password-recovery" component={PasswordRecoveryPage} />
      <Route path="/auth/password-reset" component={PasswordResetPage} />
      {/* test */}
      {/* <Route exact path="/new" component={ContentEditorPage} /> */}
      <Route path="/not-found" component={NotFoundPageView} />
      <Route
        path="/:id"
        render={() => (
          <ProtectedContent>
            <ContentEditorPage />
          </ProtectedContent>
        )}
      />

      {/* 404 */}
      <Route component={NotFoundPageView} />
    </Switch>
  </Router>
)

export default App
