import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import FileInfo from './FileInfo'
import UploadFile from './UploadFile'
import AudioFilePreview from './AudioFilePreview'
import { useFileUploader } from 'hooks/useFileUploader'
import {
  updateStepProp,
  updateTraining,
} from 'features/contentEditor/contentEditorSlice'
import { Step } from 'domain/step'
import { cloneDeep, last } from 'lodash'
import { audioContentServer } from 'config/audio'
import { useDispatch } from 'react-redux'
import AppToaster, { undoToast } from 'lib/toaster'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { useTraining } from 'hooks/useTraining'

function extractFileName(audioPath) {
  if (audioPath.startsWith('data:audio')) {
    return '<embedded audio data>'
  } else {
    // example of audioPath after upload:
    // 847d4da5-43b1-493a-b519-76148e0cc2e9/uploads/b234d61a-3c62-434c-8bd6-7ad9e3f46a50-original_filename.mp3
    const fileName = last(audioPath.split('/'))
    const cleanNameMatch = fileName.match(/(:?[^-]{4,}-){5}(.*)/)
    if (cleanNameMatch) {
      return cleanNameMatch[2] // the filename without the upload uuid tokens
    } else {
      return fileName // fallback to handle edge cases
    }
  }
}

function srcUrl(src) {
  if (src.startsWith('data:audio') || src.startsWith('http')) {
    return src
  } else {
    return `${audioContentServer}/${src}`
  }
}

const UploadFileAudio = ({ audioPath, stepId, lang, forcedMode }) => {
  // 2. instantiate an AUDIO element with src = value of audioPath
  // 3. connect AUDIO element controls + progress with AudioFilePreview (and change that name to AudioPreview!)
  const dispatch = useDispatch()
  const training = useTraining()
  const [activeSelection, setActiveSelection] = useActiveSelection()
  const onFinish = useCallback(
    (_, s3Key) => {
      dispatch(
        updateStepProp(stepId, Step.getAudioPropPath(lang), srcUrl(s3Key)),
      )
    },
    [lang, stepId],
  )

  const onDelete = useCallback(() => {
    const snapshot = cloneDeep(training)
    dispatch(updateStepProp(stepId, Step.getAudioPropPath(lang), ''))

    const toastText = `Audio ${extractFileName(audioPath)} deleted`
    AppToaster.show(
      undoToast(toastText, () => {
        dispatch(updateTraining(snapshot))
        setActiveSelection({ ...activeSelection })
      }),
    )
  }, [lang, stepId])

  const fileUploader = useFileUploader({
    fileName: audioPath,
    onFinish,
    onDelete,
  })
  const fileName = extractFileName(audioPath)
  return (
    <UploadFile
      accept=".mp3,.ogg,.wav"
      placeholder="Choose audio file..."
      fileUploader={fileUploader}
      forcedMode={forcedMode}
    >
      <FileInfo
        className="fileAudio"
        small
        fileName={fileName}
        onDelete={fileUploader.handleDelete}
        bottomBar={<AudioFilePreview src={audioPath} />}
      />
    </UploadFile>
  )
}

UploadFileAudio.propTypes = {
  audioPath: PropTypes.string,
  lang: PropTypes.string,
  stepId: PropTypes.string,
  forcedMode: UploadFile.propTypes.forcedMode,
}

export default UploadFileAudio
