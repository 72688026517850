import { randomID } from 'utils/id'

const conversions = {
  transient: transientToAnimation,
  simple: simpleToAnimation,
}

export function convertSteps(steps) {
  return steps.map(s => {
    // Apply type conversions
    const converted = conversions[s.type] ? conversions[s.type](s) : s
    // Add id if step doesn't have it
    converted.id = converted.id || randomID()
    return converted
  })
}

function simpleToAnimation(step) {
  return {
    ...step,
    type: 'animation',
    payload: {
      ...step.payload,
      node: undefined,
    },
  }
}

function transientToAnimation(step) {
  return {
    ...step,
    type: 'animation',
    payload: {
      ...step.payload,
      playOnlyOnce: true,
      transient: true,
    },
  }
}
