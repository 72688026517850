import { updateUserStats } from 'lib/aucta-backend/stats/api'

const MINUTES_TO_SLEEP = 1
const UPDATE_TIME_SEC = 20
const TRIGGER_EVENTS = ['click', 'keypress']

let asleep = false
let sleepTimer
let updateTimer

export function monitorEditingTime() {
  startSleepTimer()
  updateStatTimer()
  TRIGGER_EVENTS.forEach(e =>
    window.addEventListener(e, () => {
      asleep = false
      clearTimeout(sleepTimer)
      startSleepTimer()
    }),
  )
}

function startSleepTimer() {
  sleepTimer = setTimeout(() => {
    asleep = true
  }, MINUTES_TO_SLEEP * 60 * 1000)
}

function updateStatTimer() {
  updateTimer = setTimeout(() => {
    if (!asleep) updateEditingTime()
    updateStatTimer()
  }, UPDATE_TIME_SEC * 1000)
}

export function clearMonitorTimers() {
  clearTimeout(sleepTimer)
  clearTimeout(updateTimer)
}

function updateEditingTime() {
  updateUserStats('editingTime', undefined, UPDATE_TIME_SEC)
}
