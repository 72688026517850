import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPhonePreviewEnabled,
  setPhonePreviewEnabled,
} from 'features/uiState/uiStateSlice'
import usePlayer from '../features/contentEditor/player/hooks/usePlayer'

export function usePhonePreview() {
  const player = usePlayer()
  const dispatch = useDispatch()
  const previewEnabled = useSelector(getPhonePreviewEnabled)
  const _setPreviewEnabled = useCallback(
    v => {
      dispatch(setPhonePreviewEnabled(v))
    },
    [dispatch],
  )
  return [previewEnabled, _setPreviewEnabled]
}
