import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTempCameraPose,
  setTempCameraPose,
} from 'features/uiState/uiStateSlice'
import { isEqual } from 'lodash'

export const useTemporaryCameraPose = (stepId, origValue) => {
  const prevOrigValue = useRef(null)
  const dispatch = useDispatch()
  const pose = useSelector(getTempCameraPose(stepId))
  const setPose = useCallback(
    newPose => dispatch(setTempCameraPose(stepId, newPose)),
    [stepId, dispatch],
  )
  // keep the temp value in sync with original value updates
  useEffect(() => {
    if (!isEqual(origValue, prevOrigValue.current)) {
      dispatch(setTempCameraPose(stepId, origValue))
      prevOrigValue.current = origValue
    }
  }, [origValue])

  return [pose, setPose]
}

export const useUpdateTemporaryCameraPose = () => {
  const dispatch = useDispatch()
  return useCallback(
    (stepId, pose) => dispatch(setTempCameraPose(stepId, pose)),
    [dispatch],
  )
}
