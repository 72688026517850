import React, { useEffect } from 'react'
import PlayerProvider from './player/PlayerProvider'
import ContentEditorWrapper from './ContentEditorWrapper'
import {
  clearMonitorTimers,
  monitorEditingTime,
} from 'stats/editing-time-monitor'
import { getUrlParam } from 'utils/urlParams'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'features/userAuth/userAuthSlice'

const ContentEditorPage = () => {
  const user = useSelector(getCurrentUser)
  const isAdmin = user.isAdmin
  useEffect(() => {
    if (!getUrlParam('as') || !isAdmin) monitorEditingTime()
    return clearMonitorTimers
  }, [])

  return (
    <PlayerProvider>
      <ContentEditorWrapper />
    </PlayerProvider>
  )
}

export default ContentEditorPage
