import {
  InstructionPlayer,
  GfxContext,
  VRControllers,
  InstructionResources,
  PlayerControlPanel,
} from 'player-lib'
import { extractStepsForLang, trainingPath } from 'api/trainings'
import { retrieveRawFile } from 'lib/aucta-backend/file-system/user-content'
import { get } from 'lodash'
import { Training } from 'domain/training'
import { explorationToSteps } from 'features/contentEditor/utils'
import { idToIdx } from 'features/contentEditor/utils'
import { nodeTreeFormatDropdown, simplifyTree } from '../nodeTreeFormat'

async function retrieveModel(trainingId, modelPath) {
  const path = trainingPath(trainingId, modelPath)
  const modelData = await retrieveRawFile(path)
  return await InstructionResources.parseModel(modelData.buffer)
}

class PlayerWrapper {
  constructor() {
    this.initialized = false
  }

  async initialize() {
    // initialize components
    this.gfxContext = new GfxContext(this.rootContainer)
    this.vrControllers = new VRControllers(this.gfxContext)
    // resources
    this.resources = new InstructionResources()
    this.resources.contentConfig = this.training.config
    this.steps = extractTrainingSteps(this.training, this.lang)
    this.resources.instructionData = { steps: this.steps }
    // model & instruction player
    const modelUrl = get(this.training, 'config.model', null)
    this.model = await retrieveModel(this.training.id, modelUrl)
    this.resources.setModel(this.model)
    this.instructionPlayer = new InstructionPlayer(
      this.resources,
      this.gfxContext,
      this.vrControllers,
      true,
    )
    this.instructionPlayer.start()
    this.instructionPlayer.setMute(true)
    this.stageManager = this.instructionPlayer.stageManager
    this.nodeTree = []
    this.preventStepLoad = false

    // panel
    this.panel = new PlayerControlPanel(
      this.instructionPlayer.instructionNavigation,
      this.rootContainer,
      this.gfxContext,
      this.vrControllers,
    )

    // events
    this.gfxContext.on('frame:update', ctx => this.panel.update(ctx))
    this.instructionPlayer.on('step:load', (_idx, step) => {
      if (!this.preventStepLoad) this.panel.setStep(step)
    })
    this.instructionPlayer.instructionNavigation.on(
      'navigation-ui:hide',
      () => {
        this.panel.hide()
      },
    )
    this.instructionPlayer.instructionNavigation.on(
      'navigation-ui:show',
      () => {
        this.panel.show()
      },
    )
    // initialization donke
    this.initialized = true
  }

  async maybeInitialize() {
    if (!this.initialized && this.training && this.rootContainer && this.lang) {
      await this.initialize()
      this.refreshNodeTree()
    }
  }

  async setRootContainer(rootContainer) {
    this.rootContainer = rootContainer
    await this.maybeInitialize()
  }

  async setLang(lang) {
    if (lang === this.lang) return
    this.lang = lang
    if (!this.initialized) {
      await this.maybeInitialize()
    } else {
      this.loadInstructions(this.training)
    }
  }

  async loadModel(modelUrl) {
    console.log('!> loading new model')
    this.model = await retrieveModel(this.training.id, modelUrl)
    await this.instructionPlayer.reloadModel(this.model)
    this.instructionPlayer.reloadContentConfig(this.training.config)
    this.instructionPlayer.configureContentPresentation()
    this.refreshNodeTree()
  }

  async refreshModel() {
    await this.instructionPlayer.reloadModel(this.model)
    this.instructionPlayer.reloadContentConfig(this.training.config)
    this.instructionPlayer.configureContentPresentation()
    // @TODO this being here is ugly, but I don't see a way of solving
    // the async problem atm without changing the architecture
    this.preloadStep(null)
  }

  async loadInstructions(training) {
    if (!training || !this.instructionPlayer) return
    this.training = training
    this.steps = extractTrainingSteps(training, this.lang)
    this.instructionPlayer.reloadInstruction({ steps: this.steps })
  }

  async refreshStep() {
    if (!this.instructionPlayer || this.preventStepLoad) return
    await this.instructionPlayer.loadStep(
      this.instructionPlayer.getCurrentStepIndex(),
      true,
      1,
    )
  }

  async loadTraining(training) {
    this.training = training
    if (this.initialized) {
      const modelUrl = get(training, 'config.model', null)
      await this.loadModel(modelUrl)
      this.loadInstructions(training)
    } else {
      await this.maybeInitialize()
    }
  }

  async setActiveStep(
    stepIdx,
    forceRefresh = false,
    cameraOverride = undefined,
    instantCameraOverride = false,
  ) {
    if (!this.instructionPlayer || this.preventStepLoad) return
    const currentActiveStep = this.instructionPlayer.getCurrentStepIndex()
    if (stepIdx === null || stepIdx === -1) {
      if (stepIdx === -1) this.instructionPlayer.setInitialCamera()
      this.panel.hide()
    } else if (stepIdx !== currentActiveStep || forceRefresh) {
      await this.instructionPlayer.loadStep(stepIdx, !!cameraOverride, 1)
      if (cameraOverride) {
        this.gfxContext.interruptCameraTransition()
        this.instructionPlayer.moveCameraTo(
          cameraOverride.camera,
          cameraOverride.target,
          instantCameraOverride,
        )
      }
      const stepText = this.instructionPlayer.instructionNavigation.getStep(
        stepIdx,
      ).text
      if (stepText) this.panel.show()
      // else this.panel.hide()
    }
  }

  async preloadStep(stepIdx, preventCamera = false) {
    if (!this.instructionPlayer || this.preventStepLoad) return
    if (stepIdx === null) {
      await this.instructionPlayer.loadStepPreview(0, preventCamera)
      this.panel.hide()
    } else {
      await this.instructionPlayer.loadStepPreview(stepIdx, preventCamera)
    }
  }

  getModelInstructionNodes() {
    return this.instructionPlayer.getModelInstructionNodes()
  }

  refreshNodeTree() {
    const tree = this.instructionPlayer.getNodeTreeWithStatic()
    this.nodeTree = simplifyTree(tree)
  }

  getNodeTreeForStep(stepNodeName, selectedNodes) {
    const tree = []
    if (this.nodeTree[0]) tree.push(this.nodeTree[0])
    const stepNode = this.nodeTree.find(n => n.name === stepNodeName)
    //This avoids duplication if first node in tree has empty string as name
    // and stepnode is not set (empty string too).
    //The "TempParent" name is set in Blender when node name is empty string.
    if (stepNode && stepNode !== tree[0]) tree.push(stepNode)
    return nodeTreeFormatDropdown(tree, selectedNodes)
  }

  checkStepNodeExists(stepNode) {
    return this.nodeTree.find(n => n.name === stepNode)
  }

  freeze() {
    if (!this.instructionPlayer) return
    if (!this.instructionPlayer.isFrozen) this.instructionPlayer.freeze()
  }

  unfreeze() {
    if (!this.instructionPlayer) return
    if (this.instructionPlayer.isFrozen) this.instructionPlayer.unfreeze()
  }

  setMute(muted) {
    if (!this.instructionPlayer) return
    this.instructionPlayer.setMute(muted)
  }

  // TODO: remove if not used
  loadContentConfig(contentConfig) {
    this.training.config = contentConfig
    this.instructionPlayer.reloadContentConfig(this.training.config)
    this.instructionPlayer.configureContentPresentation()
  }

  // GfxCanvasResizing
  canvasResize(width, height) {
    this.gfxContext.canvasResize(width, height)
  }

  // Screenshots
  getThumbnail(width, height) {
    const url = this.gfxContext.getScreenshot(width, height)
    return url
  }

  async getAllThumbnails(steps, width, height) {
    this.preventStepLoad = true
    this.showStillFrame()
    const originalWidth = this.gfxContext.getBoundingRect().width
    const originalHeight = this.gfxContext.getBoundingRect().height
    this.gfxContext.canvasResize(width || 300, height || 200)
    const currentStep = this.instructionPlayer.getCurrentStepIndex() || 0
    const screenshotsURLs = []
    for (let i = 0; i < steps.length; i++) {
      const idx = idToIdx(this.training, steps[i].id)
      await this.instructionPlayer.loadStepPreview(idx)
      this.gfxContext.update()
      this.gfxContext.renderer.setAnimationLoop(null)
      const shot = this.getThumbnail()
      screenshotsURLs.push({ id: steps[i].id, url: shot })
    }
    await this.instructionPlayer.loadStepPreview(currentStep)
    this.panel.hide()
    this.gfxContext.canvasResize(originalWidth, originalHeight)
    this.removeStillFrame()
    this.preventStepLoad = false
    return screenshotsURLs
  }

  showStillFrame() {
    this.gfxContext.renderer.domElement.style.visibility = 'hidden'
    const imageURL = this.gfxContext.getScreenshot()
    const image = document.createElement('img')
    image.setAttribute('id', 'auctaFrozenFrame')
    image.src = imageURL
    image.classList.add('canvas-still-frame')
    const parent = document.querySelector('.contentEditor-content')
    parent.appendChild(image)
  }

  removeStillFrame() {
    const stillFrame = document.getElementById('auctaFrozenFrame')
    if (!stillFrame) return
    const parent = document.querySelector('.contentEditor-content')
    parent.removeChild(stillFrame)
    this.gfxContext.renderer.domElement.style.visibility = 'visible'
  }

  // events

  onDoubleClick(handler) {
    if (!this.gfxContext) return
    this.gfxContext.on('annotation:point', handler)
  }

  offDoubleClick(handler) {
    if (!this.gfxContext) return
    this.gfxContext.off('annotation:point', handler)
  }

  onCameraChange(handler) {
    if (!this.gfxContext) return
    this.gfxContext.on('camera:reposition', handler)
  }

  offCameraChange(handler) {
    if (!this.gfxContext || !handler) return
    this.gfxContext.off('camera:reposition', handler)
  }

  onStepChange(handler) {
    if (!this.instructionPlayer) return
    this.instructionPlayer.on('step:load', handler)
  }

  offStepChange(handler) {
    if (!this.instructionPlayer || !handler) return
    this.instructionPlayer.off('step:load', handler)
  }
}

// Utils

export function extractTrainingSteps(training, lang) {
  if (Training.hasExploration(training)) {
    const steps = explorationToSteps(Training.getExploration(training))
    return extractStepsForLang(steps, lang)
  } else {
    return extractStepsForLang(training.steps, lang)
  }
}

export default PlayerWrapper
