import { retrieveRawFile } from 'lib/aucta-backend/file-system/user-content'

export async function getModelStepNodes(modelRoute) {
  const modelData = await retrieveRawFile(modelRoute, false)
  const jsonBufSize = modelData.readUInt32LE(12)
  const jsonString = modelData.toString('utf8', 20, jsonBufSize + 20)
  const modelObject = JSON.parse(jsonString)
  modelObject.scene = modelObject.scene || 0
  const stepNodes = _getModelStepNodes(modelObject)
  if (!stepNodes) return undefined
  return stepNodes.map(n => n.name)
}

function _getModelStepNodes(modelObject) {
  const scene = modelObject.scenes[modelObject.scene]
  const nodes = modelObject.nodes
  const instructionsNode =
    nodes[scene.nodes.find(id => nodes[id].name === 'instructions')]
  if (!instructionsNode?.children) return undefined
  const stepNodes = instructionsNode.children.map(id => nodes[id])
  return stepNodes
}
