import { Position, Toaster } from '@blueprintjs/core'
import ChartPie from 'ui/components/charts/ChartPie'
import cx from 'classnames'

/** Singleton toaster instance */
const AppToaster = Toaster.create({
  maxToasts: 3,
  position: Position.TOP_RIGHT,
  className: 'toaster-visible',
})

export const undoToast = (text, onUndo) => {
  const message = (
    <>
      <ChartPie percent={100} duration={5000} /> {text}
    </>
  )

  return {
    message: message,
    className: cx('custom-toast', {
      'custom-toast-big': false,
    }),
    icon: null,
    action: {
      text: <span>Undo</span>,
      onClick: onUndo,
    },
  }
}

export default AppToaster
