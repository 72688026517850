import { useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  getActiveSelection,
  setActiveSelection,
  setRenderedStep,
} from 'features/uiState/uiStateSlice'
import {
  getTraining,
  updateAnnotationProp,
} from 'features/contentEditor/contentEditorSlice'
import { Training } from 'domain/training'

export function useActiveSelection() {
  const dispatch = useDispatch()
  const training = useSelector(getTraining)
  const activeSelection = useSelector(getActiveSelection)
  const maybeDeselectActiveAnnotation = () => {
    if (activeSelection.type === 'annotation') {
      dispatch(updateAnnotationProp(activeSelection.id, ['selected'], false, true))
    }
  }
  const _setActiveSelection = useCallback(
    v => {
      if (v === 'default') {
        if (Training.hasExploration(training)) {
          _setActiveSelection({
            type: 'step',
            id: Training.getExplorationStep(training).id,
          })
        } else {
          _setActiveSelection({
            type: 'step',
            id: Training.getSteps(training)[0].id,
          })
        }
      } else if (v.type === 'step') {
        batch(() => {
          maybeDeselectActiveAnnotation();
          dispatch(setRenderedStep(Training.getEntityById(training, v.id)?.id))
          dispatch(
            setActiveSelection({
              ...v,
              parentId:
                v.parentId ||
                Training.getAnnotationContainingStep(training, v.id)?.id,
            }),
          )
        })
      } else if (v.type === 'annotation') {
        batch(() => {
          maybeDeselectActiveAnnotation();
          dispatch(setRenderedStep(Training.getExplorationStep(training)?.id))
          dispatch(setActiveSelection(v))
          dispatch(updateAnnotationProp(v.id, ['selected'], true, true))
        })
      } else if (v.type === null) {
        batch(() => {
          maybeDeselectActiveAnnotation();
          dispatch(setRenderedStep(null))
          dispatch(setActiveSelection(v))
        })
      }
    },
    [dispatch, training],
  )
  return [activeSelection, _setActiveSelection]
}
