import { Droppable } from 'react-beautiful-dnd'
import DraggableWrapper from './DraggableWrapper'
import { Step } from 'domain/step'
import StepCard from './StepCard'
import { useUiState } from 'hooks/useUiState'
import { useActiveSelection } from 'hooks/useActiveSelection'

const DroppableStepsContainer = ({ annotationId, steps, className }) => {
  const [activeSelection] = useActiveSelection()
  const stepId = activeSelection.id
  const [associatedNodes] = useUiState('model-step-nodes')

  return (
    <Droppable droppableId={annotationId || 'step-list'}>
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="droppable-list"
        >
          {steps.map((step, i) => (
            <DraggableWrapper key={step.id} entityId={step.id} listIndex={i}>
              <StepCard
                step={step}
                isActive={stepId === step.id}
                missingNode={
                  associatedNodes &&
                  Step.getAssociatedNode(step) &&
                  !associatedNodes.includes(Step.getAssociatedNode(step))
                }
              />
            </DraggableWrapper>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default DroppableStepsContainer
