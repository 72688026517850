import { useEffect } from 'react'
import { Classes } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import { contentConfigProps } from 'lib/customPropTypes'
import ModelSelection from 'ui/components/model-sidebar/ModelSelection'
import ModelConfig from 'ui/components/model-sidebar/ModelConfig'
import ModelScale from 'ui/components/model-sidebar/ModelScale'
import ThumbsSidebar from '../thumbs-sidebar/ThumbsSidebar'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { Training } from 'domain/training'
import useThumbnails from 'hooks/useThumbnails'

const ModelSidebar = ({ contentConfig, training, isLoading }) => {
  const player = usePlayer()
  const [, setActiveSelection] = useActiveSelection()
  const [, refreshThumbnails] = useThumbnails()

  useEffect(() => {
    setActiveSelection({ type: null, id: null })
    player.preloadStep(null)
  }, [isLoading])

  useEffect(() => {
    // @TODO Elias: Deberiamos actualizar SIEMPRE los thumbnails o te parece demasiado bestia?
    if (player.initialized && Training.hasThumbnailsMissing(training)) {
      window.requestAnimationFrame(() => {
        refreshThumbnails()
      })
    }
  }, [player.initialized])

  return (
    <ThumbsSidebar>
      <ModelSelection
        contentConfig={contentConfig}
        className={isLoading ? Classes.SKELETON : ''}
        trainingId={training?.id}
      />

      <ModelConfig
        contentConfig={contentConfig}
        className={isLoading ? Classes.SKELETON : ''}
      />

      <ModelScale
        contentConfig={contentConfig}
        className={isLoading ? Classes.SKELETON : ''}
      />
    </ThumbsSidebar>
  )
}

ModelSidebar.propTypes = {
  trainingId: PropTypes.string,
  isLoading: PropTypes.bool,
  contentConfig: contentConfigProps,
}

export default ModelSidebar
