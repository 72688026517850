import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, set } from 'features/uiState/uiStateSlice'

export const useUiState = (key, defaultValue = undefined) => {
  const dispatch = useDispatch()
  const value = useSelector(get(key))
  const setValue = useCallback(
    newValue => {
      // console.log(`--> ui-state-set: <${key}> <- ${newValue}`)
      dispatch(set(key, newValue))
    },
    [key, dispatch],
  )
  return [value === undefined ? defaultValue : value, setValue]
}
