import { Icon } from '@blueprintjs/core'
import { updateAnnotationProp } from 'features/contentEditor/contentEditorSlice'
import { useUiState } from 'hooks/useUiState'
import React, { useCallback, useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'

const ResetPosition = ({ annotation }) => {
  const dispatch = useDispatch()
  const [repositioningAnnotation, setRepositioningAnnotation] = useUiState(
    'repositioning-annotation',
  )

  const repositionHandler = useCallback(() => {
    if (repositioningAnnotation) {
      batch(() => {
        setRepositioningAnnotation(null)
        dispatch(updateAnnotationProp(annotation.id, ['hidden'], false, true))
      })
    } else {
      batch(() => {
        setRepositioningAnnotation(annotation.id)
        dispatch(updateAnnotationProp(annotation.id, ['hidden'], true))
      })
    }
  }, [repositioningAnnotation, annotation.id])

  useEffect(() => {
    return () => {
      batch(() => {
        setRepositioningAnnotation(null)
        dispatch(updateAnnotationProp(annotation.id, ['hidden'], false, true))
      })
    }
  }, [annotation.id])

  return (
    <button onClick={repositionHandler} className="reset-position">
      <Icon icon="map-marker" />
      {repositioningAnnotation ? (
        <span className="reset-position-text">Cancel repositioning</span>
      ) : (
        <span className="reset-position-text">Reposition annotation</span>
      )}
    </button>
  )
}

export default ResetPosition
