import { Button } from '@blueprintjs/core'
import {
  removeAnnotation,
  updateTraining,
} from 'features/contentEditor/contentEditorSlice'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import AppToaster, { undoToast } from 'lib/toaster'
import { Annotation } from 'domain/annotation'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { cloneDeep } from 'lodash-es'
import { useTraining } from 'hooks/useTraining'

const DeleteAnnotationButton = ({ annotation, lang }) => {
  const dispatch = useDispatch()
  const [activeSelection, setActiveSelection] = useActiveSelection()
  const training = useTraining()

  const handleDeleteAnnotation = useCallback(() => {
    const snapshot = cloneDeep(training)
    dispatch(removeAnnotation(annotation.id))
    setActiveSelection('default')

    const toastText = `Annotation ${
      Annotation.getLabel(annotation, lang) || 'unlabeled'
    } deleted`
    AppToaster.show(
      undoToast(toastText, () => {
        dispatch(updateTraining(snapshot))
        setActiveSelection({ ...activeSelection })
      }),
    )
  }, [annotation])

  return (
    <Button
      onClick={handleDeleteAnnotation}
      icon="trash"
      className="button-icon"
    />
  )
}

export default DeleteAnnotationButton
