import React from 'react'
import logo from '../../../assets/images/aucta-logo.svg'
import logoSm from '../../../assets/images/aucta-icon.svg'

const Header = () => (
  <header className="auth-header">
    <div className="wrapper">
      <img className="auth-logoSm" alt="Aucta logo" src={logoSm} />
      <img className="auth-logo" alt="Aucta logo" src={logo} />
      <span className="auth-project">Editor</span>
    </div>
  </header>
)

export default Header
