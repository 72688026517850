import { addNewStep } from 'features/contentEditor/contentEditorSlice'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { randomID } from 'utils/id'

export const useCreateStep = () => {
  const dispatch = useDispatch()
  const createStep = useCallback(
    (cameraPose, associatedNode, targetAnnotation) => {
      const stepId = randomID()
      dispatch(
        addNewStep({
          cameraPose,
          associatedNode,
          targetAnnotation,
          stepId,
        }),
      )
      return stepId
    },
    [dispatch],
  )

  return createStep
}
