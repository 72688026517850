import { Training } from 'domain/training'
import { ContentConfig } from 'domain/contentConfig'
import {
  retrieveJson,
  persistJson,
  updateJson,
} from 'lib/aucta-backend/file-system/user-content'
import { createInvalidation } from 'api/invalidations'
import { isEmpty, omit, pick } from 'lodash'
import { explorationToSteps } from 'features/contentEditor/utils'

export function extractStepsForLang(steps = [], lang) {
  return steps
    .map(step => ({
      ...omit(step, ['instructions']),
      ...step.instructions[lang],
    }))
    .map((step, i) => {
      if (step.type !== 'exploration') return step
      return {
        ...step,
        payload: {
          ...step.payload,
          hotspots: step.payload.hotspots.map(hotspot => ({
            ...hotspot,
            label: hotspot.label[lang],
          })),
        },
      }
    })
}

export function splitStepsForLangs(contentConfig, steps) {
  const instructions = {}
  for (const lang of ContentConfig.getLanguages(contentConfig)) {
    instructions[lang] = extractStepsForLang(steps, lang)
  }
  return instructions
}

export function joinStepsFromAllLangs(languages, instructions) {
  // use the first lang as reference for everything except text+audio
  const steps = instructions[0].steps.map(s => omit(s, ['text', 'audio']))
  return steps
    .map((step, i) => ({
      ...step,
      instructions: languages.reduce(
        (acc, lang, j) => ({
          ...acc,
          [lang]: pick(instructions[j].steps[i], ['text', 'audio']),
        }),
        {},
      ),
    }))
    .map((step, i) =>
      step.type === 'exploration'
        ? joinHotspotStepLabelsFromAllLangs(step, i, languages, instructions)
        : step,
    )
}

function joinHotspotStepLabelsFromAllLangs(
  step,
  stepIdx,
  languages,
  instructions,
) {
  return {
    ...step,
    payload: {
      ...step.payload,
      hotspots: step.payload.hotspots.map((hotspot, i) => ({
        ...hotspot,
        label: languages.reduce(
          (acc, lang, j) => ({
            ...acc,
            [lang]: instructions[j].steps[stepIdx].payload.hotspots[i].label,
          }),
          {},
        ),
      })),
    },
  }
}

export function trainingPath(id, path) {
  return `trainings/${id}/${path}`
}

export function makePathRelativeToTraining(path) {
  if (path.match('trainings')) {
    return path.split('/').slice(3).join('/')
  } else {
    return path
  }
}

async function retrieveTrainingSteps(trainingId, config) {
  const languages = config.languages
  const instructions = await Promise.all(
    languages.map(lang =>
      retrieveJson(trainingPath(trainingId, `instructions/${lang}.json`)),
    ),
  )
  return joinStepsFromAllLangs(languages, instructions)
}

async function persistTrainingInstructions(trainingId, steps, contentConfig) {
  const instructions = splitStepsForLangs(contentConfig, steps)
  for (const [lang, instruction] of Object.entries(instructions)) {
    const path = trainingPath(trainingId, `instructions/${lang}.json`)
    await persistJson(path, { steps: instruction })
  }
}

export async function retrieveTraining(trainingId) {
  const training = await retrieveJson(trainingPath(trainingId, 'training.json'))
  if (!training || training === '' || isEmpty(training))
    throw new TrainingNotFoundError()
  const config = await retrieveJson(trainingPath(trainingId, 'config.json'))
  const steps = await retrieveTrainingSteps(trainingId, config)
  return { id: trainingId, training, config, steps }
}

export async function persistTraining(trainingData) {
  const id = Training.getId(trainingData)
  const config = Training.getContentConfig(trainingData)
  const steps = Training.hasExploration(trainingData)
    ? explorationToSteps(Training.getExploration(trainingData), true)
    : Training.getSteps(trainingData)
  const details = Training.getDetails(trainingData)
  await persistJson(trainingPath(id, 'config.json'), config)
  await persistTrainingInstructions(id, steps, config)
  await persistTrainingDetails(id, details, steps)
  await createInvalidation(trainingPath(id, '*'))
}

export async function persistTrainingDetails(id, training, steps) {
  const updatedAt = new Date().getTime()
  const totalSteps = steps.length
  const updated = { ...training, updatedAt, totalSteps }
  await persistJson(trainingPath(id, 'training.json'), updated)
  await persistTrainingList(updated)
}

export async function persistTrainingList(training) {
  updateJson('trainings-list.json', trainingList => {
    trainingList.forEach((item, i) => {
      if (item.id === training.id) {
        trainingList[i] = training
      }
    })
    return trainingList
  })
}

export class TrainingNotFoundError extends Error {
  constructor() {
    super("Training doesn't exist")
    this.name = 'TrainingNotFoundError'
  }
}
