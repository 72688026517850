import { userFilePath } from 'api/storage'
import environment from 'config/environment'
import { initializeBackend } from 'lib/aucta-backend'
import { currentCredentials } from 'lib/aucta-backend/auth'
import { updateOptions as updateFilesystemOptions } from 'lib/aucta-backend/file-system'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from './app/store'
import './assets/stylesheets/style.scss'

async function main() {
  await initializeBackend({
    credentialsProvider: currentCredentials,
    environmentProps: environment,
  })
  updateFilesystemOptions({
    userPathProvider: userFilePath,
  })
  render()
}

const render = () => {
  const App = require('./app/App').default
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  )
}

main()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render)
}
