import { Icon } from '@blueprintjs/core'
import cx from 'classnames'

const LanguageTab = ({ text, selected, onSelect, onDelete }) => {
  return (
    <div className="langEditor-tab">
      <button
        className={cx('langEditor-tabItem', { 'is-selected': selected })}
        onClick={onSelect}
      >
        {text}
      </button>
      <button onClick={onDelete} className="delete-button">
        <Icon icon="delete" />
      </button>
    </div>
  )
}

export default LanguageTab
