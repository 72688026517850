import PropTypes from 'prop-types'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import { TextArea } from '@blueprintjs/core'
import { Step } from 'domain/step'
import { stepProps } from 'lib/customPropTypes'
import UploadFileAudio from 'ui/components/upload-file/UploadFileAudio'
import LangEditorRow from './LangEditorRow'
import { get } from 'lodash'

const StepRow = ({ step, lang }) => {
  const titleField = useInputUpdater(step.title, v =>
    updateStepProp(step.id, 'title', v),
  )

  const descriptionField = useInputUpdater(step.instructions[lang]?.text, v =>
    updateStepProp(step.id, Step.getInstructionTextPath(lang), v),
  )

  const actualLength = get(descriptionField.value, 'length', 0)

  return (
    <LangEditorRow
      lang={lang}
      step={step}
      isStep
      key={`step-${step.title || step.id}`}
      titleField={titleField}
    >
      <div className="langEditor-cell is-description">
        <div className="textarea-wrapper">
          <TextArea
            rows={1}
            growVertically={true}
            onChange={descriptionField.onChange}
            value={descriptionField.value}
            onKeyPress={descriptionField.onKeyPress}
            maxLength="100"
          />
          <div className="textarea-counter">{actualLength}/100</div>
        </div>
      </div>
      <div className="langEditor-cell is-audio">
        <UploadFileAudio
          stepId={step.id}
          audioPath={Step.getAudioFileName(step, lang)}
          lang={lang}
        />
      </div>
    </LangEditorRow>
  )
}

StepRow.propTypes = {
  lang: PropTypes.string,
  step: stepProps,
}

export default StepRow
