import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActivePage, setActivePage } from 'features/uiState/uiStateSlice'

export function useActivePage() {
  const dispatch = useDispatch()
  const activePage = useSelector(getActivePage)
  const _setActivePage = useCallback(v => dispatch(setActivePage(v)), [
    dispatch,
  ])
  return [activePage, _setActivePage]
}
