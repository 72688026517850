import {
  applyExpandedState,
  findNodeInTree,
  traverseTree,
} from 'features/contentEditor/player/nodeTreeFormat'
import React, { useCallback, useMemo, useState } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

const DropdownNodeSelection = ({ tree, onChange, mode, texts }) => {
  const [expanded, setExpanded] = useState([])

  const onNodeToggle = useCallback(
    node => {
      // collapse children of collapsed nodes
      const childrenToCollapse = []
      if (!node.expanded) {
        traverseTree(findNodeInTree(tree, node).children, n => {
          childrenToCollapse.push(n.label)
        })
      }

      setExpanded(
        [...expanded.filter(n => n.label !== node.label), node].filter(
          n => n.expanded && !childrenToCollapse.includes(n.label),
        ),
      )
    },
    [expanded],
  )

  const expandedTree = useMemo(() => {
    return applyExpandedState(tree, expanded)
  }, [tree, expanded])

  return (
    <DropdownTreeSelect
      className="dropdown-tree"
      data={expandedTree}
      onChange={onChange}
      onNodeToggle={onNodeToggle}
      mode={mode}
      texts={texts}
    />
  )
}

export default React.memo(
  DropdownNodeSelection,
  (nextProps, prevProps) =>
    nextProps.onChange === prevProps.onChange &&
    nextProps.tree === prevProps.tree,
)
