import { currentAuthenticatedUser, currentUserToken } from '../auth'
import { env } from '../env'
import axios from 'axios'

const USER_STATS_FILE = 'user-stats.json'
// @TODO environments might have their own buckets
const BUCKET = 'manager-user-content'

// ------------------
// player analytics

export async function retrieveCustomerStats(
  dayRange,
  customer,
  authorizationHeader,
) {
  const authorization = authorizationHeader || (await currentUserToken())
  const url =
    customer === 'all'
      ? `${env.playerAnalyticsAPI}/overview/${dayRange}`
      : `${env.playerAnalyticsAPI}/customer/${customer}/${dayRange}`

  const response = await fetch(url, {
    headers: { Authorization: authorization },
  })
  const json = await response.json()
  if (response.status === 403)
    throw new AuthorizationError(JSON.stringify(json))
  if (response.status !== 200) throw JSON.stringify(json)
  return json.data
}

export async function retrieveTrainingStats(
  customerId,
  trainingId,
  dayRange,
  authorizationHeader,
) {
  const authorization = authorizationHeader || (await currentUserToken())
  const response = await fetch(
    `${env.playerAnalyticsAPI}/customer/${customerId}/${trainingId}/${dayRange}`,
    {
      headers: { Authorization: authorization },
    },
  )
  const json = await response.json()
  if (response.status !== 200) throw JSON.stringify(json)
  return json.data
}

export async function getCustomerList(dayRange = 365, authorizationHeader) {
  const authorization = authorizationHeader || (await currentUserToken())
  const response = await fetch(
    `${env.playerAnalyticsAPI}/customer/${dayRange}`,
    {
      headers: { Authorization: authorization },
    },
  )
  const json = await response.json()
  if (response.status !== 200) throw JSON.stringify(json)
  return json.data
}

export async function getCustomersReport(dayRange, authorizationHeader) {
  const authorization = authorizationHeader || (await currentUserToken())
  const response = await fetch(
    `${env.playerAnalyticsAPI}/customers-report/${dayRange}`,
    {
      headers: { Authorization: authorization },
    },
  )
  const json = await response.json()
  if (response.status !== 200) throw JSON.stringify(json)
  return json.data
}

// ------------------
// user stats

export async function updateUserStats(key, targetUserId) {
  const userToken = await currentUserToken()
  const userId = targetUserId || (await currentAuthenticatedUser()).username
  const response = await axios.post(
    `/u/${userId}/user/stats`,
    { stat: key },
    {
      baseURL: env.managerAPI,
      headers: { Authorization: userToken },
    },
  )
  return response.data
}

// -------------------
// Errors

class AuthorizationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AccessDeniedException'
  }
}
