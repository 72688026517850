const envName =
  process.env['REACT_APP_ENV'] || process.env['NODE_ENV'] || 'development'

export const region = 'eu-central-1'

export const managerIdentityPoolId =
  'eu-central-1:0facab09-4f8f-4dd9-86ea-b0760a0b3f00'
export const managerIdentityPoolUnauthRole =
  'arn:aws:iam::396824024623:role/Cognito_AuctaManager_Unauth_Role'
const userPoolId = 'eu-central-1_5ujVLjWUG'
const poolArn =
  'arn:aws:cognito-idp:eu-central-1:396824024623:userpool/eu-central-1_5ujVLjWUG'
const userPoolWebClientId = '27pp77s8sp6bst5u5lsvpavp6n'

export const configBucket = 'aucta-config'
export const userContentBucket = 'manager-user-content'

const domain = window?.location?.hostname
const formattedDomain = domain
  ? '.' + domain.split('.').slice(1).join('.')
  : '.aucta.io'

const cookieStorage = {
  domain: formattedDomain,
  expires: 365,
  sameSite: 'lax',
  secure: true,
}

export const managerAuthConfig = {
  region,
  identityPoolId: managerIdentityPoolId,
  userPoolId,
  poolArn,
  userPoolWebClientId,
}

Object.assign(
  managerAuthConfig,
  envName === 'production' || envName === 'staging' ? { cookieStorage } : {},
)
