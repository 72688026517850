import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import cx from 'classnames'

const FileInfo = ({
  fileName,
  onReload,
  onDelete,
  bottomBar,
  small,
  className,
}) => {
  return (
    <div className={cx('uploadFile-loaded', { [className]: className })}>
      <div className="uploadFile-audio">
        <span className="uploadFile-name">{fileName}</span>
        {onDelete ? (
          <Button icon="trash" onClick={onDelete} small={small} />
        ) : null}

        {onReload ? (
          <Button text="Reload" icon="upload" onClick={onReload} small />
        ) : null}
      </div>
      {bottomBar}
    </div>
  )
}

FileInfo.propTypes = {
  fileName: PropTypes.string,
  onReload: PropTypes.func,
  onDelete: PropTypes.func,
  bottomBar: PropTypes.node,
  small: PropTypes.bool,
  className: PropTypes.string,
}

export default FileInfo
