import { useActiveSelection } from 'hooks/useActiveSelection'
import { useTraining } from 'hooks/useTraining'
import React, { useRef, useEffect } from 'react'
import usePlayer from './hooks/usePlayer'
import { idxToId } from '../utils'
import { useUpdateTemporaryCameraPose } from 'hooks/useTemporaryCameraPose'
import { Training } from 'domain/training'
import { batch, useDispatch, useSelector } from 'react-redux'
import { getRenderedStep, STEPS_PAGE } from 'features/uiState/uiStateSlice'
import { useCreateAnnotation } from 'hooks/useCreateAnnotation'
import { useUiState } from 'hooks/useUiState'
import { updateAnnotationProp } from '../contentEditorSlice'
import cx from 'classnames'
import { useActivePage } from 'hooks/useActivePage'
import PhonePreviewFrame from 'features/phonePreview/PhonePreviewFrame'

const Player = ({ children, lang, phonePreview }) => {
  const rootContainer = useRef(null)
  const [activeSelection, setActiveSelection] = useActiveSelection()
  const [, setAnnotationRequested] = useUiState('annotation-requested')
  const [repositioningAnnotation, setRepositioningAnnotation] = useUiState(
    'repositioning-annotation',
  )

  const renderedStep = useSelector(getRenderedStep)
  const updateTempCameraPose = useUpdateTemporaryCameraPose()
  const createAnnotation = useCreateAnnotation()

  const [activePage, setActivePage] = useActivePage()
  const dispatch = useDispatch()
  const training = useTraining()
  const player = usePlayer()

  useEffect(() => {
    player.setRootContainer(rootContainer.current)
  }, [rootContainer.current])

  useEffect(() => {
    player.setLang(lang)
  }, [lang])

  // change activeStep when player loads a different step
  useEffect(() => {
    const stepHandler = index => {
      const id = idxToId(training, index)
      if (id !== activeSelection?.id && id !== renderedStep) {
        setActiveSelection({
          type: 'step',
          id,
        })
        if (activePage !== STEPS_PAGE) {
          setActivePage(STEPS_PAGE)
        }
      }
    }

    const cameraHandler = camPose => {
      const vecPose = {
        camera: {
          x: camPose.position[0],
          y: camPose.position[1],
          z: camPose.position[2],
        },
        target: {
          x: camPose.target[0],
          y: camPose.target[1],
          z: camPose.target[2],
        },
      }
      updateTempCameraPose(activeSelection.id, vecPose)
    }

    const doubleClickHandler = point => {
      if (Training.hasExploration(training)) {
        if (repositioningAnnotation) {
          batch(() => {
            dispatch(
              updateAnnotationProp(
                repositioningAnnotation,
                ['position'],
                [point.x, point.y, point.z],
              ),
            )
            setRepositioningAnnotation(null)
            dispatch(
              updateAnnotationProp(
                repositioningAnnotation,
                ['hidden'],
                false,
                true,
              ),
            )
          })
        } else {
          batch(() => {
            const annotationId = createAnnotation([point.x, point.y, point.z])
            setActiveSelection({
              type: 'annotation',
              id: annotationId,
            })
          })
        }
      } else {
        setAnnotationRequested([point.x, point.y, point.z])
      }
    }

    player.onStepChange(stepHandler)
    player.onCameraChange(cameraHandler)
    player.onDoubleClick(doubleClickHandler)
    return () => {
      player.offStepChange(stepHandler)
      player.offCameraChange(cameraHandler)
      player.offDoubleClick(doubleClickHandler)
    }
  })

  return (
    <div
      className={cx('player', {
        'cursor-reposition': repositioningAnnotation,
        'phone-preview-active': phonePreview,
      })}
      ref={rootContainer}
    >
      <PhonePreviewFrame />
      {children}
    </div>
  )
}

export default Player
