import React from 'react'
import AnnotationLabel from './AnnotationLabel'
import ResetPosition from './ResetPosition'
import SidebarBlock from '../sidebar/SidebarBlock'
import PropTypes from 'prop-types'
import { stepProps } from 'lib/customPropTypes'
import { Annotation } from 'domain/annotation'
import PropertiesSidebarHeader from './PropertiesSidebarHeader'
import DeleteAnnotationButton from './DeleteAnnotationButton'

const AnnotationPropertiesSidebar = ({ annotation, lang = '' }) => {
  if (!annotation) return null

  return (
    <>
      <PropertiesSidebarHeader
        deleteSlot={
          <DeleteAnnotationButton annotation={annotation} lang={lang} />
        }
      >
        {Annotation.getLabel(annotation, lang) || 'Unlabeled annotation'}
      </PropertiesSidebarHeader>
      <SidebarBlock className="divider">
        <AnnotationLabel annotation={annotation} lang={lang} />
      </SidebarBlock>
      <SidebarBlock>
        <ResetPosition annotation={annotation} />
      </SidebarBlock>
    </>
  )
}

AnnotationPropertiesSidebar.propTypes = {
  stepsRemaining: PropTypes.number,
  step: stepProps,
  lang: PropTypes.string,
}

export default AnnotationPropertiesSidebar
