import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import { useTemporaryCameraPose } from 'hooks/useTemporaryCameraPose'
import { useDispatch } from 'react-redux'
import { Icon } from '@blueprintjs/core'
import { useActiveSelection } from 'hooks/useActiveSelection'
import cx from 'classnames'
import useThumbnails from 'hooks/useThumbnails'

const StepCameraButton = ({ stepId, camera, target }) => {
  const [cameraSaved, setCameraSaved] = useState(true)
  const [activeSelection] = useActiveSelection()
  const dispatch = useDispatch()

  const overridenCamera = activeSelection.cameraOverride
  const [setThumbnail] = useThumbnails()
  const [tempPose] = useTemporaryCameraPose(
    stepId,
    overridenCamera || {
      camera,
      target,
    },
  )

  const tempPoseHash = JSON.stringify(tempPose)

  useEffect(() => {
    setCameraSaved(tempPoseHash === JSON.stringify({ camera, target }))
  }, [tempPoseHash])

  const commit = () => {
    dispatch(
      updateStepProp(stepId, ['payload', 'camera'], tempPose.camera, true),
    )
    dispatch(
      updateStepProp(stepId, ['payload', 'target'], tempPose.target, true),
    )
    setThumbnail(stepId)
    setCameraSaved(true)
  }

  return (
    <button onClick={commit} className="button-icon">
      <Icon icon="camera" className={cx({ unsaved: !cameraSaved })} />
    </button>
  )
}

StepCameraButton.propTypes = {
  stepId: PropTypes.string,
  camera: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
  target: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
}

export default StepCameraButton
