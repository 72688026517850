import { createSlice } from '@reduxjs/toolkit'
import Auth, { isUserAdmin } from 'lib/aucta-backend/auth'
import { initializeCDN } from 'lib/aucta-backend/cdn'
import { credentialRefreshInterval } from 'config/backend'
import { initializeS3 } from 'lib/aucta-backend/file-system'

const initialState = {
  currentUser: null,
}

const userAuth = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setUserSession(state, action) {
      state.currentUser = action.payload
      state.error = null
    },
    unsetUserSession(state) {
      state.currentUser = null
    },
  },
})

export default userAuth

// -------------------------
// selectors

export const getCurrentUser = state => state.userAuth.currentUser

// -------------------------
// async actions

export const fetchAuthenticatedUser = () => async dispatch => {
  const user = await Auth.currentAuthenticatedUser()
  dispatch(setUserSession(user))
}

export const registerUserAction =
  (email, password, name, surname, company) => async () => {
    await Auth.signUp(email, password, name, surname, company)
  }

export const loginUserAction = (email, password) => async dispatch => {
  const user = await Auth.signIn(email, password)
  dispatch(setUserSession(user))
}

const setUserSession = user => dispatch => {
  user.attributes.isAdmin = isUserAdmin(user)
  dispatch(userAuth.actions.setUserSession(user.attributes))
  refreshCredentials()
}

export const recoverPasswordAction = email => async () => {
  await Auth.forgotPassword(email)
}

export const resetPasswordAction = (email, code, password) => async () => {
  const resp = await Auth.recoveryChangePassword(email, code, password)
  console.log('> RESP:', resp)
}

export const logoutUserAction = () => async dispatch => {
  await Auth.signOut()
  dispatch(userAuth.actions.unsetUserSession)
}

const refreshCredentials = () => {
  Auth.currentSession()
  initializeCDN(true)
  initializeS3(true)
  setTimeout(() => refreshCredentials(), credentialRefreshInterval)
}
