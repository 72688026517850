import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@blueprintjs/core'
import cx from 'classnames'

const ButtonIcon = ({ onClick, icon, disabled, text, className }) => {
  return (
    <button
      className={cx('button-icon', className)}
      onClick={onClick}
      disabled={disabled}
      title={text}
    >
      <Icon icon={icon} />
      <span className="screen-reader-text">{text}</span>
    </button>
  )
}

ButtonIcon.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
}

export default ButtonIcon
