import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import AssociatedNode from '../fields/AssociatedNode'
import PlayOnlyOnce from '../fields/PlayOnlyOnce'
import TransientToggle from '../fields/TransientToggle'
import { Step, ANIMATION_STEP, INTERACTIVE_STEP } from 'domain/step'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import { RadioGroup, Radio } from '@blueprintjs/core'
import DropdownNodeSelection from './DropdownNodeSelection'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import { batch, useDispatch } from 'react-redux'
import useThumbnails from 'hooks/useThumbnails'
import { disableTopLevel } from 'features/contentEditor/player/nodeTreeFormat'

const StepType = ({ stepId, step }) => {
  const typeField = useInputUpdater(
    Step.getType(step),
    v => updateStepProp(stepId, 'type', v),
    0,
  )

  const player = usePlayer()
  const dispatch = useDispatch()
  const [setThumbnail] = useThumbnails()

  const triggerTree = useMemo(() => {
    const tree = player.getNodeTreeForStep(
      step.payload.node,
      step.payload.triggers || [],
    )
    return disableTopLevel(tree)
  }, [step.payload.node, step.payload.triggers])

  const setTriggerNodes = useCallback(
    (_currentNode, selectedNodes) =>
      batch(() => {
        let triggerNodes = selectedNodes.map(node => node.label)
        if (selectedNodes.length === 0) triggerNodes = undefined
        dispatch(
          updateStepProp(step.id, ['payload', 'triggers'], triggerNodes, true),
        )
        setThumbnail(step.id)
      }),
    [updateStepProp, step.id, setThumbnail],
  )

  return (
    <div className="sidebar-stepType">
      <RadioGroup
        className="radio-tabs"
        onChange={typeField.onChange}
        selectedValue={typeField.value}
      >
        <Radio
          label={
            <span className="radio-tabs-text">
              {Step.selectableStepTypes[0].label}
            </span>
          }
          value={Step.selectableStepTypes[0].value}
        />
        <Radio
          label={
            <span className="radio-tabs-text">
              {Step.selectableStepTypes[1].label}
            </span>
          }
          value={Step.selectableStepTypes[1].value}
        />
      </RadioGroup>

      {Step.hasAssociatedNode(step) && (
        <AssociatedNode stepId={stepId} node={Step.getAssociatedNode(step)} />
      )}

      {Step.getType(step) === INTERACTIVE_STEP && (
        <DropdownNodeSelection
          tree={triggerTree}
          onChange={setTriggerNodes}
          mode={'multiSelect'}
          texts={{ placeholder: 'Interactive triggers' }}
        />
      )}

      {Step.getType(step) === ANIMATION_STEP && (
        <PlayOnlyOnce stepId={stepId} value={Step.getPlayOnlyOnce(step)} />
      )}
      {Step.getType(step) === ANIMATION_STEP && (
        <TransientToggle
          stepId={stepId}
          isDisabled={!Step.getPlayOnlyOnce(step)}
          value={Step.getTransient(step)}
        />
      )}
    </div>
  )
}

StepType.propTypes = {
  stepId: PropTypes.string,
  stepType: PropTypes.string,
}

export default StepType
