import React, { useCallback, useMemo } from 'react'
import TooltipCheck from '../tooltips/TooltipCheck'
import ColorPickerBlock from '../color-picker-block/ColorPickerBlock'
import useThumbnails from 'hooks/useThumbnails'
import { useDispatch } from 'react-redux'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import DropdownNodeSelection from '../fields/DropdownNodeSelection'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import { disableTopLevel } from 'features/contentEditor/player/nodeTreeFormat'

const StepFeatures = ({ step, stepIdx }) => {
  // const typeField = useInputUpdater(
  //   Step.getType(step),
  //   v => updateStepProp(stepIdx, 'type', v),
  //   0,
  // )
  const dispatch = useDispatch()
  const [setThumbnail] = useThumbnails()
  const player = usePlayer()

  const setHighlightEnable = value => {
    dispatch(
      updateStepProp(step.id, ['payload', 'highlightEnabled'], value, true),
    )
    setThumbnail(step.id)
  }

  // Handler for highlights node selection

  const highlightsTree = useMemo(() => {
    const tree = player.getNodeTreeForStep(
      step.payload.node,
      step.payload.highlights || [],
    )
    return disableTopLevel(tree)
  }, [step.payload.node, step.payload.highlights])

  const setHighlightNodes = useCallback(
    (currentNode, selectedNodes) => {
      const highlightNodes = selectedNodes.map(node => node.label)
      dispatch(
        updateStepProp(
          step.id,
          ['payload', 'highlights'],
          highlightNodes,
          true,
        ),
      )
      setThumbnail(step.id)
    },
    [updateStepProp, step.id, setThumbnail],
  )

  return (
    <>
      <TooltipCheck
        checked={step.payload.highlightEnabled || false}
        checkLabel={'Apply highlight effect'}
        tooltipContent={
          'Selected elements will be visually highlighted to guide the users attention.'
        }
        onChange={e => {
          setHighlightEnable(e.target.checked)
        }}
        largeSwitch={true}
      />

      {step.payload.highlightEnabled && (
        <div>
          <ColorPickerBlock step={step} />
          <DropdownNodeSelection
            tree={highlightsTree}
            onChange={setHighlightNodes}
            mode={'multiSelect'}
            texts={{ placeholder: 'Nodes to highlight' }}
          />
        </div>
      )}
    </>
  )
}

export default StepFeatures
