import PropTypes from 'prop-types'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateAnnotationLabel } from 'features/contentEditor/contentEditorSlice'
import LangEditorRow from './LangEditorRow'

const AnnotationRow = ({ annotation, label, lang }) => {
  const labelField = useInputUpdater(label, v =>
    updateAnnotationLabel(annotation.id, v, lang),
  )

  return (
    <LangEditorRow titleField={labelField} key={`annotation-${annotation.id}`}>
      <div className="langEditor-cell is-description"></div>
      <div className="langEditor-cell is-audio"></div>
    </LangEditorRow>
  )
}

AnnotationRow.propTypes = {
  explorationStepIndex: PropTypes.number,
  annotationIndex: PropTypes.number,
  label: PropTypes.string,
  lang: PropTypes.string,
}

export default AnnotationRow
