import React, { useRef, useCallback } from 'react'
import PlayerContext from './PlayerContext'
import PlayerWrapper from './api/player'

// React Component

const PlayerProvider = ({ children }) => {
  const player = useRef(new PlayerWrapper())

  const setupPlayer = useCallback(async (rootContainer, training, steps) => {
    await player.current.initialize(rootContainer, training, steps)
  }, [])

  return <PlayerContext.Provider value={{ setupPlayer, player: player.current }}>
    {children}
  </PlayerContext.Provider>

}

export default PlayerProvider
