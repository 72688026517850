import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useUiState } from 'hooks/useUiState'
import toaster from 'lib/toaster'

import { fetchAuthenticatedUser } from 'features/userAuth/userAuthSlice'
import { Intent } from '@blueprintjs/core'

import SpinnerOverlay from 'ui/components/spinner/SpinnerOverlay'

const ProtectedContent = ({ children }) => {
  const [isLoading, setLoading] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const [, setTrainingUrl ] = useUiState('trainingUrl')
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const triedToGoTo = history.location.pathname
    setTrainingUrl(triedToGoTo)
    const recoverSession = async () => {
      try {
        await dispatch(fetchAuthenticatedUser())
        setLoading(false)
      } catch (e) {
        toaster.show({
          icon: 'lock',
          intent: Intent.WARNING,
          message: e.message || e,
        })
        history.push('/auth/login')
      }
    }
    recoverSession()
  }, [])
  return isLoading ? <SpinnerOverlay /> : <>{children}</>
}

export default ProtectedContent
