import { Draggable } from 'react-beautiful-dnd'
import cx from 'classnames'

const DraggableWrapper = ({ children, entityId, listIndex }) => {
  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    }
  }

  return (
    <Draggable draggableId={entityId} index={listIndex}>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getStyle(provided.draggableProps.style, snapshot)}
          >
            <div
              className={cx('draggable-item', {
                'is-dragging': snapshot.isDragging,
              })}
            >
              {children}
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

export default DraggableWrapper
