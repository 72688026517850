import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import cx from 'classnames'

const TooltipCheck = ({
  checked = false,
  checkLabel,
  tooltipContent,
  onChange,
  largeSwitch,
  isDisabled,
}) => {
  return (
    <div className={cx('tooltip-check', { disabled: isDisabled })}>
      <Switch
        large={largeSwitch}
        label={checkLabel}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
      />
      <Tooltip2
        className="tooltip-question"
        content={tooltipContent}
        placement="top"
        usePortal={false}
      >
        <span className="question">?</span>
      </Tooltip2>
    </div>
  )
}

TooltipCheck.defaultProps = {
  largeSwitch: true,
}

TooltipCheck.propTypes = {
  checkLabel: PropTypes.string,
  tooltipContent: PropTypes.string,
  largeSwitch: PropTypes.bool,
}

export default TooltipCheck
