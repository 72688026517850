import React from 'react'
import Header from 'ui/components/headers/Header'
import Footer from 'ui/components/footer/Footer'
import { Card } from '@blueprintjs/core'

const AuthLayout = ({ children, title, subtitle }) => (
  <div className="auth-container">
    <Header />
    <main className="auth-content">
      <Card className="auth-card">
        <h2 className="auth-title">{title}</h2>
        {subtitle ? <h6 className="auth-subtitle">{subtitle}</h6> : null}
        {children}
      </Card>
    </main>
    <Footer />
  </div>
)

export default AuthLayout
