import React from 'react'
import PropTypes from 'prop-types'
import { Toaster, Toast, Position } from '@blueprintjs/core'
import cx from 'classnames'

const CustomToast = ({ isBig, icon, text, action }) => {
  return (
    <Toaster position={Position.TOP_CENTER}>
      <Toast
        className={cx('custom-toast', {
          'custom-toast-big': isBig,
        })}
        icon={icon ? icon : null}
        message={text}
        action={action}
      />
    </Toaster>
  )
}

CustomToast.propTypes = {
  isBig: PropTypes.bool,
  text: PropTypes.node,
  icon: PropTypes.string,
}

export default CustomToast
