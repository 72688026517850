import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { contentConfigProps } from 'lib/customPropTypes'
import SidebarBlock from '../sidebar/SidebarBlock'
import { ContentConfig } from 'domain/contentConfig'
import { trainingPath } from 'api/trainings'
import { getTempLink } from 'lib/aucta-backend/file-system/user-content'
import ModelFile from 'ui/components/upload-file/ModelFile'
import { useUiState } from 'hooks/useUiState'
import { Callout } from '@blueprintjs/core'
import cx from 'classnames'

const ModelSelection = ({ contentConfig = {}, className, trainingId }) => {
  const [modelUrl, setModelUrl] = useState(undefined)
  const modelRoute = ContentConfig.getModel(contentConfig)
  const modelName = ContentConfig.getModelName(contentConfig)
  const [improperStructure, setImproperStructure] = useUiState(
    'improper-model-structure',
  )

  useEffect(async () => {
    const relativePath = trainingPath(trainingId, modelRoute)
    setModelUrl(await getTempLink(relativePath))
  }, [modelRoute, trainingId])

  return (
    <SidebarBlock label="Model Settings" className={className}>
      <ModelFile
        fileName={modelName}
        onAfterUpload={modelHasImproperStructure => {
          setImproperStructure(modelHasImproperStructure)
        }}
      />
      {improperStructure && (
        <Callout icon="warning-sign" intent="warning">
          Your model seems to not contain steps (instructions). Therefore the
          animation functionality of the Editor will be disabled.
        </Callout>
      )}
      <div className="downloadFile">
        <a
          href={modelUrl}
          className={cx('full-button', {
            disabled: !modelUrl,
          })}
        >
          Download the 3D model
        </a>
      </div>
    </SidebarBlock>
  )
}

ModelSelection.propTypes = {
  contentConfig: contentConfigProps,
  className: PropTypes.string,
  trainingId: PropTypes.string,
}

export default ModelSelection
