import { combineReducers } from '@reduxjs/toolkit'
import userAuth from 'features/userAuth/userAuthSlice'
import contentEditor from 'features/contentEditor/contentEditorSlice'
import uiState from 'features/uiState/uiStateSlice'

const rootReducer = combineReducers({
  userAuth: userAuth.reducer,
  contentEditor: contentEditor.reducer,
  uiState: uiState.reducer,
})

export default rootReducer
