import { batch } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import { retrieveTraining, persistTraining } from 'api/trainings'
import { ContentConfig } from 'domain/contentConfig'
import { Training } from 'domain/training'
import { makePathRelativeToTraining } from 'api/trainings'
import { convertSteps } from './stepConversion'
import { stepsToExploration } from './utils'
import uiState from 'features/uiState/uiStateSlice'

const initialState = {
  training: {
    config: ContentConfig.INITIAL_STATE,
    steps: [],
    exploration: undefined,
    training: {},
  },
}

const contentEditor = createSlice({
  name: 'contentEditor',
  initialState,
  reducers: {
    setTrainingData(state, action) {
      state.training = action.payload
    },
    setModelFile(state, action) {
      state.training = Training.updateContentConfig(
        state.training,
        contentConfig => ContentConfig.setModel(contentConfig, action.payload),
      )
    },
    setModelFileName(state, action) {
      state.training = Training.updateContentConfig(
        state.training,
        contentConfig =>
          ContentConfig.setModelName(contentConfig, action.payload),
      )
    },
    setTrainingThumbnail(state, action) {
      const newThumbnailUrl = action.payload
      state.training = Training.updateTrainingThumbnail(
        state.training,
        newThumbnailUrl,
      )
    },
    setInitialCameraPose(state, action) {
      state.training = Training.updateContentConfig(
        state.training,
        contentConfig =>
          ContentConfig.setInitialCameraPose(contentConfig, action.payload),
      )
    },
    updateConfigOption(state, action) {
      const { optionName, value } = action.payload
      state.training = Training.updateContentConfig(
        state.training,
        contentConfig =>
          ContentConfig.setConfigOption(contentConfig, optionName, value),
      )
    },
    updateContentScale(state, action) {
      const scale = action.payload
      state.training = Training.updateContentConfig(
        state.training,
        contentConfig => ContentConfig.setContentScale(contentConfig, scale),
      )
    },
    removeAvailableLanguage(state, action) {
      const lang = action.payload
      state.training = Training.removeAvailableLanguage(state.training, lang)
    },
    addAvailableLanguage(state, action) {
      const lang = action.payload
      state.training = Training.addAvailableLanguage(state.training, lang)
    },
    updateStepProp(state, action) {
      const { stepId, propName, value } = action.payload
      const updated = Training.updateStepProp(
        state.training,
        stepId,
        propName,
        value,
      )
      state.training = updated
    },
    updateAnnotationProp(state, action) {
      const { annotationId, propName, value } = action.payload
      if (!Training.getExploration(state.training)) return
      const updated = Training.updateAnnotationProp(
        state.training,
        annotationId,
        propName,
        value,
      )
      state.training = updated
    },
    removeStep(state, action) {
      const stepId = action.payload
      state.training = Training.removeStep(state.training, stepId)
    },
    addStep(state, action) {
      const { step, index } = action.payload
      state.training = Training.addStep(state.training, step, index)
    },
    addNewStep(state, action) {
      const {
        cameraPose,
        associatedNode,
        stepId,
        targetAnnotation,
      } = action.payload
      if (targetAnnotation) {
        state.training = Training.addNewStepToAnnotation(
          state.training,
          targetAnnotation,
          stepId,
          cameraPose,
          associatedNode,
        )
      } else {
        state.training = Training.addNewStep(
          state.training,
          stepId,
          cameraPose,
          associatedNode,
        )
      }
    },
    setSteps(state, action) {
      const steps = action.payload
      state.training = Training.setSteps(state.training, steps)
    },
    setAnnotations(state, action) {
      const annotations = action.payload
      state.training = Training.setAnnotations(state.training, annotations)
    },
    addNewAnnotation(state, action) {
      const { annotationId, position } = action.payload
      state.training = Training.addNewAnnotation(
        state.training,
        annotationId,
        position,
      )
    },
    removeAnnotation(state, action) {
      const annotationId = action.payload
      state.training = Training.removeAnnotation(state.training, annotationId)
    },
    duplicateStep(state, action) {
      const stepIdx = action.payload
      state.training = Training.duplicateStep(state.training, stepIdx)
    },
    setAnnotationSteps(state, action) {
      const steps = action.payload.steps
      const annotationId = action.payload.annotationId
      state.training = Training.setAnnotationSteps(
        state.training,
        annotationId,
        steps,
      )
    },
    changeToExplorationMode(state, action) {
      const {
        annotationId,
        annotationPosition,
        cameraPose,
        associatedNode,
      } = action.payload
      state.training = Training.changeToExplorationMode(
        state.training,
        annotationId,
        annotationPosition,
        cameraPose,
        associatedNode,
      )
    },
  },
})

export default contentEditor

// -------------------------
// selectors

export const getTraining = state => state.contentEditor.training

export const getAnnotations = state =>
  state.contentEditor.training.exploration?.annotations

export const getNextOrPreviousStep = stepId => state => {
  return Training.getNextOrPreviousStep(state.contentEditor.training, stepId)
}

export const getEntityById = id => state => {
  return Training.getEntityById(state.contentEditor.training, id)
}

// -------------------------
// async actions

export const fetchTraining = trainingId => async dispatch => {
  const training = await retrieveTraining(trainingId)
  training.steps = convertSteps(training.steps)
  if (training.steps.find(s => s.type === 'exploration')) {
    training.exploration = stepsToExploration(training.steps)
  }
  dispatch(contentEditor.actions.setTrainingData(training))
  return training
}

export const updateTraining = training => async dispatch => {
  dispatch(contentEditor.actions.setTrainingData(training))
}

export const saveTraining = training => async dispatch => {
  await persistTraining(training)
  dispatch(contentEditor.actions.setTrainingData(training))
}

// -------------------------
// specific update action

export const setModelFile = (modelName, modelPath) => async dispatch => {
  batch(() => {
    dispatch(
      contentEditor.actions.setModelFileName(
        makePathRelativeToTraining(modelName),
      ),
    )
    dispatch(
      contentEditor.actions.setModelFile(makePathRelativeToTraining(modelPath)),
    )
  })
}

export const setTrainingThumbnail = url => dispatch => {
  dispatch(contentEditor.actions.setTrainingThumbnail(url))
}

export const setInitialCameraPose = pose => {
  return contentEditor.actions.setInitialCameraPose(pose)
}

export const updateConfigOption = (optionName, value) => dispatch => {
  dispatch(contentEditor.actions.updateConfigOption({ optionName, value }))
}

export const updateContentScale = scale => dispatch => {
  dispatch(contentEditor.actions.updateContentScale(Number(scale)))
}

export const removeAvailableLanguage = lang => {
  return contentEditor.actions.removeAvailableLanguage(lang)
}

export const addAvailableLanguage = lang => {
  return contentEditor.actions.addAvailableLanguage(lang)
}

export const updateStepProp = (stepId, propName, value, preventRefresh) => {
  if (preventRefresh)
    return contentEditor.actions.updateStepProp({
      stepId,
      propName,
      value,
    })

  return stepRefreshWrapper(
    contentEditor.actions.updateStepProp({
      stepId,
      propName,
      value,
    }),
  )
}

export const removeStep = stepId => {
  return contentEditor.actions.removeStep(stepId)
}

// export const addStep = (step, index) => {
//   return contentEditor.actions.addStep({ step, index })
// }

export const addNewStep = payload => {
  return contentEditor.actions.addNewStep(payload)
}

export const duplicateStep = stepIdx => {
  return contentEditor.actions.duplicateStep(stepIdx)
}

export const setSteps = steps => {
  return contentEditor.actions.setSteps(steps)
}

export const addNewAnnotation = payload => {
  return contentEditor.actions.addNewAnnotation(payload)
}

export const removeAnnotation = annotationId => {
  return contentEditor.actions.removeAnnotation(annotationId)
}

export const setAnnotations = annotations => {
  return contentEditor.actions.setAnnotations(annotations)
}

export const setAnnotationSteps = (annotationId, steps) => {
  const payload = { annotationId: annotationId, steps: steps }
  return contentEditor.actions.setAnnotationSteps(payload)
}

export const updateAnnotationProp = (
  annotationId,
  propName,
  value,
  preventRefresh,
) => {
  const actionCreator = contentEditor.actions.updateAnnotationProp({
    annotationId,
    propName,
    value,
  })
  if (preventRefresh) {
    return actionCreator
  } else {
    return stepRefreshWrapper(actionCreator)
  }
}

export const updateAnnotationLabel = (annotationId, value, lang) => {
  return stepRefreshWrapper(
    contentEditor.actions.updateAnnotationProp({
      annotationId,
      propName: ['label', lang],
      value,
    }),
  )
}

export const changeToExplorationMode = (
  annotationId,
  annotationPosition,
  cameraPose,
  associatedNode,
) => {
  return contentEditor.actions.changeToExplorationMode({
    annotationId,
    annotationPosition,
    cameraPose,
    associatedNode,
  })
}

// Utils

const stepRefreshWrapper = action => dispatch => {
  batch(() => {
    dispatch(action)
    dispatch(uiState.actions.requireStepRefresh())
  })
}
