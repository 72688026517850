import React from 'react'
import Header from 'ui/components/headers/Header'
import Footer from 'ui/components/footer/Footer'

const AppLayout = ({ children }) => (
  <div id="outer-container" className="outer-container">
    <Header />
    <div className="content">{children}</div>
    <Footer />
  </div>
)

export default AppLayout
