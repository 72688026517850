import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMuted, setMuted } from 'features/uiState/uiStateSlice'
import usePlayer from '../features/contentEditor/player/hooks/usePlayer'

export function useMutedControl() {
  const dispatch = useDispatch()
  const muted = useSelector(getMuted)
  const player = usePlayer()
  const _setMuted = useCallback(
    v => {
      dispatch(setMuted(v))
      player.setMute(v)
    },
    [dispatch],
  )
  return [muted, _setMuted]
}
