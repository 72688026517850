import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const InfoPiece = ({ label, value, caption }) => {
  return (
    <div
      title={`${label} ${value}`}
      className={cx('infoPiece', {
        caption: caption,
      })}
    >
      <span className="infoPiece-label">{label}</span>
      <span className="infoPiece-value">{value}</span>
    </div>
  )
}

InfoPiece.propTypes = {
  label: PropTypes.string,
  caption: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default InfoPiece
