import { v4 as uuid } from 'uuid'
import { trainingPath } from 'api/trainings'
import { uploadFile } from 'lib/aucta-backend/file-system/user-content'
import { currentAuthenticatedUser, isUserAdmin } from 'lib/aucta-backend/auth'
import { getUrlParam } from 'utils/urlParams'

export async function userFilePath(path) {
  const user = await currentAuthenticatedUser()
  const isAdmin = isUserAdmin(user)
  const userId =
    isAdmin && getUrlParam('as') ? getUrlParam('as') : user.username
  if (path.startsWith(userId)) {
    return path
  } else {
    return `${userId}/${path}`
  }
}

export async function uploadTrainingFile(file, trainingId, progressHandler) {
  const id = uuid()
  const folder = trainingPath(trainingId, 'uploads')
  const fileName = file.name.replace(/[^a-zA-Z0-9-_.]/g, '')
  const filePath = `${folder}/${id}-${fileName}`.replace(/\s]/g, '_')
  return uploadFile(file, filePath)
}
