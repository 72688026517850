import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Slider, Icon, Switch } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import SidebarBlock from '../sidebar/SidebarBlock'
import { contentConfigProps } from 'lib/customPropTypes'
import { updateContentScale } from 'features/contentEditor/contentEditorSlice'
import { ContentConfig } from 'domain/contentConfig'
import { isNumber } from 'lodash'
import usePlayer from 'features/contentEditor/player/hooks/usePlayer'
import { useUpdateConfigOption } from 'hooks/useUpdateConfigOption'
import useThumbnails from 'hooks/useThumbnails'

function useDebouncedEffect(fn, ms, deps, stop = false) {
  const timeoutId = useRef()
  useEffect(() => {
    if (stop) return
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(fn, ms)
  }, deps)
}

const ModelScale = ({ contentConfig, className }) => {
  const autoScale = ContentConfig.hasAutoScale(contentConfig)
  const scale = ContentConfig.getContentScale(contentConfig)
  const dispatch = useDispatch()
  const player = usePlayer()
  const [scaleValue, setScaleValue] = useState(scale)
  const [inputScaleValue, setInputScaleValue] = useState(scale)
  const updateConfigOption = useUpdateConfigOption()
  const [, refreshThumbnails] = useThumbnails()

  // only accept valid inputs from the text field
  const updateInputScaleValue = async () => {
    const n = Math.max(0, Number(inputScaleValue))
    if (isNumber(n)) {
      setInputScaleValue(n)
      await dispatch(updateContentScale(n))
      await player.refreshModel()
    } else {
      setInputScaleValue(scaleValue)
    }
  }

  // update store after inputs change (debounced)
  useDebouncedEffect(
    () => {
      dispatch(updateContentScale(scaleValue))
      player.refreshModel()
      refreshThumbnails()
    },
    500,
    [dispatch, scaleValue],
    scaleValue === scale,
  )

  // reflect store changes in the inputs
  useEffect(() => {
    setScaleValue(scale)
    setInputScaleValue(scale)
  }, [scale])

  // reflect slider changes in the text field
  useEffect(() => {
    if (scaleValue === scale) return
    setInputScaleValue(Number(scaleValue).toFixed(3))
  }, [scaleValue])

  return (
    <>
      <SidebarBlock label="Model Scale" className={className}>
        <Switch
          checked={autoScale}
          label="Auto scale"
          large
          onChange={e =>
            updateConfigOption(ContentConfig.AUTO_SCALE, e.target.checked)
          }
        />

        {!autoScale && (
          <div className="sidebar-slider">
            <Slider
              min={0}
              max={10}
              stepSize={0.01}
              labelStepSize={3}
              labelSize={2}
              value={scaleValue}
              onChange={v => setScaleValue(parseFloat(v.toFixed(3)))}
            />
            <input
              className="bp3-input value-input"
              type="number"
              min={0}
              value={inputScaleValue}
              onChange={e => setInputScaleValue(e.target.value)}
              onKeyPress={e => e.charCode === 13 && updateInputScaleValue()}
              onBlur={updateInputScaleValue}
            />
            <div className="sidebar-slider-controls">
              <button
                onClick={() => setScaleValue(scaleValue + 0.1)}
                className="control control-up"
              >
                <Icon icon="chevron-up" />
              </button>
              <button
                onClick={() => setScaleValue(scaleValue - 0.1)}
                className="control control-down"
              >
                <Icon icon="chevron-down" />
              </button>
            </div>
          </div>
        )}
      </SidebarBlock>
    </>
  )
}

ModelScale.propTypes = {
  contentConfig: contentConfigProps,
  className: PropTypes.string,
}

export default ModelScale
