import React from 'react'
import cx from 'classnames'
import { stringToHex } from 'ui/utils/hexConversions'

const ColorSample = ({ color, selectedColor, onSelect, className }) => {
  const setBorder = () => {
    return stringToHex(color) === selectedColor ? '2px' : '0px'
  }

  return (
    <div
      onClick={onSelect}
      className={cx('sample-color', {
        [className]: className,
        'is-selected': selectedColor === stringToHex(color),
      })}
      style={{
        backgroundColor: `${color}`,
        border: `${setBorder()} solid #fff`,
        boxShadow: `0px 0px 0px ${setBorder()} ${color}`,
      }}
    ></div>
  )
}

export default ColorSample
