import { Training } from 'domain/training'
import { omit, cloneDeep } from 'lodash'
import { randomID } from 'utils/id'

export function idToIdx(training, stepId) {
  if (Training.hasExploration(training)) {
    return getStepIndex(training.exploration, stepId)
  } else {
    return training.steps.findIndex(s => s.id === stepId)
  }
}

export function idxToId(training, index) {
  if (Training.hasExploration(training)) {
    const annotations = training.exploration.annotations
    const steps = annotations.reduce((acc, a) => acc.concat(a.steps), [
      training.exploration.explorationStep,
    ])
    return steps[index].id
  } else {
    return training.steps[index].id
  }
}

function getStepIndex(exploration, stepId) {
  if (exploration.explorationStep.id === stepId) return 0
  let idx = 1 // we take explorationStep into account
  for (let annotation of exploration.annotations) {
    for (let step of annotation.steps) {
      if (step.id === stepId) return idx
      idx++
    }
  }
}

export function stepsToExploration(steps) {
  const explorationStep = steps.find(s => s.type === 'exploration')
  const hotspots = explorationStep.payload.hotspots

  const annotations = hotspots.map(hs => {
    const start = steps.findIndex(s => s.id === hs.navigationTarget)
    const end = steps.findIndex(s => s.id === hs.navigationEnd) + 1
    return {
      ...omit(hs, ['navigationTarget', 'navigationEnd']),
      id: randomID(),
      steps: steps
        .slice(start, end)
        .map(step => ({ ...cloneDeep(step), id: randomID() })),
    }
  })

  return {
    explorationStep,
    annotations,
  }
}

export function explorationToSteps(exploration, publishable = false) {
  const annotations = exploration.annotations
  const labelsHidden = exploration.explorationStep.payload.hideLabels
  const explorationStep = {
    ...exploration.explorationStep,
    payload: {
      ...exploration.explorationStep.payload,
      hotspots: annotations.map(annotation => ({
        label: annotation.label,
        hidden: publishable ? false : annotation.hidden,
        selected: publishable ? false : annotation.selected,
        labelVisible: !labelsHidden,
        position: annotation.position,
        navigationTarget: annotation.steps[0]?.id,
        navigationEnd: annotation.steps.slice(-1)[0]?.id,
      })),
    },
  }
  let steps = [explorationStep]
  for (let annotation of annotations) {
    steps = steps.concat(annotation.steps)
  }
  return steps
}
