import React from 'react'
import PropTypes from 'prop-types'

const ChartPie = ({ percent = 0, size, duration = 3000 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className="chart-pie"
      viewBox="0 0 220 220"
    >
      <defs>
        <filter
          id="f-inner-shadow"
          width="101.8%"
          height="101.8%"
          x="-.9%"
          y="-.9%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="1.5"
          ></feGaussianBlur>
          <feOffset
            dy="1"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <style>
        {`
    .pie-percent {
      stroke-dasharray: 616px;
      stroke-dashoffset: 616px;
      transform: rotate3d(0,0,1,-90deg); /* To start the percent from the top position */
      transform-origin: center;
      stroke-width: 24;
      stroke: #0292ff;
      fill: none;
      animation: stroke-animate ease-in-out ${duration}ms forwards;
    }
    @keyframes stroke-animate {
      from {stroke-dashoffset: 616px;}
    }
`}
      </style>
      <circle
        cx="110"
        cy="110"
        r="98"
        fill="none"
        stroke="#E6E6E6"
        strokeWidth="24"
        className="pie-base"
      ></circle>
      <circle
        cx="110"
        cy="110"
        r="98"
        style={{ strokeDashoffset: `${616 - percent * 6.16}px` }}
        className="pie-percent"
      ></circle>
    </svg>
  )
}

ChartPie.propTypes = {
  size: PropTypes.number,
  percent: PropTypes.number,
}

ChartPie.defaultProps = {
  size: 20,
}

export default ChartPie
