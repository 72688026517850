import PropTypes from 'prop-types'
import LanguageTabs from 'ui/language-editor/LanguageTabs'
import StepTable from 'ui/language-editor/StepTable'
import { stepProps } from 'lib/customPropTypes'
import { Tooltip2 } from '@blueprintjs/popover2'

const LanguageEditorPageView = ({
  availableLanguages,
  selectedLanguage,
  instruction,
}) => {
  return (
    <div className="langEditor-container">
      <h1 className="langEditor-title">Language Editor</h1>
      <h3 className="langEditor-subtitle">
        Selected Language
        <Tooltip2
          className="tooltip-question"
          content="The selected tab will be the language shown while editing."
          placement="top"
          usePortal={false}
        >
          <span className="question">?</span>
        </Tooltip2>
      </h3>
      <div className="langEditor-tabs">
        <LanguageTabs
          availableLanguages={availableLanguages}
          selectedLanguage={selectedLanguage}
        />
        <StepTable
          instruction={instruction}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </div>
  )
}

LanguageEditorPageView.propTypes = {
  selectedLanguage: PropTypes.string,
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
  instruction: PropTypes.shape({
    steps: PropTypes.arrayOf(stepProps),
    annotations: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.objectOf(PropTypes.string),
        steps: PropTypes.arrayOf(stepProps),
      }),
    ),
  }),
}

export default LanguageEditorPageView
