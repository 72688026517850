import PropTypes from 'prop-types'

// For react-router (e.g. Link and Redirect components)
export const link = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  PropTypes.func,
])

export const cameraPoseProps = PropTypes.shape({
  camera: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
  target: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
})

export const stepProps = PropTypes.shape({
  payload: PropTypes.shape({
    node: PropTypes.string,
    highlights: PropTypes.array,
    animation: PropTypes.array,
    camera: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number,
    }),
    target: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number,
    }),
  }),
  title: PropTypes.string,
  type: PropTypes.string,
  instructions: PropTypes.shape({
    en: PropTypes.shape({
      audio: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
})

export const contentConfigProps = PropTypes.shape({
  disableBackfaceCulling: PropTypes.bool,
  fixMaterialMetalness: PropTypes.bool,
  sRGBEncoding: PropTypes.bool,
  gammaEncoding: PropTypes.bool,
})

export const trainingProps = PropTypes.shape({
  config: contentConfigProps,
  steps: PropTypes.arrayOf(stepProps),
  training: PropTypes.shape({
    description: PropTypes.string,
    distribution: PropTypes.string,
    distributionToken: PropTypes.string,
    id: PropTypes.string,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    totalSessions: PropTypes.number,
    totalSteps: PropTypes.number,
    updatedAt: PropTypes.number,
  }),
})
