import { batch, useDispatch } from 'react-redux'
import { Alert, Intent, H5 } from '@blueprintjs/core'
import { useUiState } from 'hooks/useUiState'
import {
  changeToExplorationMode,
  updateTraining,
} from 'features/contentEditor/contentEditorSlice'
import { useCallback } from 'react'
import { randomID } from 'utils/id'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { ReactComponent as AnnotationIcon } from '../../../assets/icons/icon-annotation.svg'
import AppToaster, { undoToast } from 'lib/toaster'
import { cloneDeep } from 'lodash-es'
import { useTraining } from 'hooks/useTraining'

const AnnotationModeAlert = ({ cameraPose, associatedNode }) => {
  const dispatch = useDispatch()
  const training = useTraining()
  const [annotationRequested, setAnnotationRequested] = useUiState(
    'annotation-requested',
  )
  const [activeSelection, setActiveSelection] = useActiveSelection()

  const snapshot = cloneDeep(training)
  const handleModeChange = useCallback(() => {
    batch(() => {
      const annotationId = randomID()
      dispatch(
        changeToExplorationMode(
          annotationId,
          annotationRequested,
          cameraPose,
          associatedNode,
        ),
      )
      setAnnotationRequested(null)
      setActiveSelection({ type: 'annotation', id: annotationId })
    })
    const toastText = 'Switched to annotation mode'
    AppToaster.show(
      undoToast(toastText, () => {
        dispatch(updateTraining(snapshot))
        setActiveSelection({ ...activeSelection })
      }),
    )
  }, [annotationRequested, associatedNode, cameraPose, dispatch])

  return (
    <Alert
      isOpen={annotationRequested}
      icon={<AnnotationIcon className="icon" />}
      cancelButtonText="Cancel"
      confirmButtonText="Activate Annotations"
      intent={Intent.PRIMARY}
      onCancel={() => {
        setAnnotationRequested(null)
      }}
      onConfirm={handleModeChange}
      className="custom-alert"
    >
      <H5>Do you want to add your first annotation?</H5>
      <p>
        Please choose carefully, as this change is permanent. If you proceed,
        your existing steps will be copied into an annotation, and your entire
        training will be based on annotations.
      </p>
    </Alert>
  )
}

export default AnnotationModeAlert
