import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from '@blueprintjs/core'
import ButtonIcon from '../buttons/ButtonIcon'
import { audioContentServer } from 'config/audio'

function formatTime(seconds) {
  const min = String(Math.floor(seconds / 60)).padStart(2, '0')
  const secs = String(Math.round(seconds - min * 60)).padStart(2, '0')
  return `${min}:${secs}`
}

function srcUrl(src) {
  if (src.startsWith('data:audio') || src.startsWith('http')) {
    return src
  } else {
    return `${audioContentServer}/${src}`
  }
}

const AudioFilePreview = ({ src }) => {
  const audioFile = useRef(new Audio())
  const timerId = useRef(null)

  const [state, setState] = useState('STOP')

  const [ellapsed, setEllapsed] = useState('00:00')
  const [totalTime, setTotalTime] = useState('00:00')
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    clearTimeout(timerId.current)
    if (!src || src === '') return
    audioFile.current.src = srcUrl(src)
    audioFile.current.load()
    setProgress(audioFile.current.currentTime / audioFile.current.duration)
    setEllapsed(formatTime(0))
    const onLoad = () => {
      setTotalTime(formatTime(audioFile.current.duration))
      audioFile.current.removeEventListener('durationchange', onLoad)
    }
    audioFile.current.addEventListener('durationchange', onLoad)
  }, [src])

  const onTick = () => {
    if (audioFile.current.paused) {
      clearTimeout(timerId.current)
      setProgress(0)
      setEllapsed(formatTime(0))
      setState('STOP')
    } else {
      setProgress(audioFile.current.currentTime / audioFile.current.duration)
      setEllapsed(formatTime(audioFile.current.currentTime))
    }
  }

  const handlePlay = () => {
    setState('PLAY')
    audioFile.current.play()
    timerId.current = setInterval(onTick, 100)
  }
  const handleStop = () => {
    setState('STOP')
    audioFile.current.load()
    onTick()
  }

  return (
    <div className="uploadFile-controls">
      {state === 'STOP' && (
        <ButtonIcon icon="play" onClick={handlePlay} text="play" />
      )}
      {state === 'PLAY' && (
        <ButtonIcon icon="stop" onClick={handleStop} text="stop" />
      )}
      <ProgressBar stripes={false} value={progress} />
      <span className="uploadFile-time">
        {ellapsed}/{totalTime}
      </span>
    </div>
  )
}

AudioFilePreview.propTypes = {
  src: PropTypes.string,
}

export default AudioFilePreview
