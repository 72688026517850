import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from '@blueprintjs/core'
import { modes as uploadModes } from 'hooks/useFileUploader'
import UploadForm from './UploadForm'
import cx from 'classnames'

const UploadFile = ({
  children,
  placeholder,
  accept,
  fileUploader,
  className,
  offerReupload = false,
  forcedMode = undefined,
}) => {
  // "forcedMode" is only for storybook
  const mode = forcedMode || fileUploader.mode

  // this component manages 3 small subcomponents with a simple FSM
  switch (mode) {
    case uploadModes.PROGRESS:
      return (
        <ProgressBar
          className={cx('uploadFile-loadingBar', className)}
          intent="primary"
          stripes={false}
          animate={true}
          value={fileUploader.progress}
        />
      )
    case uploadModes.EMPTY:
    case uploadModes.UPLOAD:
      return (
        <UploadForm
          accept={accept}
          text={placeholder}
          onFileSelected={fileUploader.handleUpload}
          className={className}
        />
      )
    case uploadModes.READY:
      if (offerReupload)
        return (
          <UploadForm
            accept={accept}
            text={placeholder}
            onFileSelected={fileUploader.handleUpload}
            className={className}
          />
        )
      return children
    default:
      return children
  }
}

UploadFile.propTypes = {
  children: PropTypes.node,
  accept: PropTypes.string,
  placeholder: PropTypes.string,
  // @TODO: document the same of this object
  fileUploader: PropTypes.object,
  forcedMode: PropTypes.oneOf([
    uploadModes.EMPTY,
    uploadModes.UPLOAD,
    uploadModes.READY,
    uploadModes.PROGRESS,
  ]),
}

export default UploadFile
