import { useUiState } from 'hooks/useUiState'
import { debounce } from 'lodash-es'
import { autosaveInterval } from 'config/global'
import { persistTraining } from 'api/trainings'
import { env as environment } from 'lib/aucta-backend/env'

const publishTraining = debounce(async (training, setSaveState, force) => {
  console.log('!> publishing...')
  setSaveState('saving')
  try {
    if (environment.autosave || force) {
      await persistTraining(training)
    }
    setSaveState('saved')
    window.onbeforeunload = undefined
  } catch (err) {
    console.error('Error publishing: ', err)
    setSaveState('error')
  } finally {
    setTimeout(() => {
      setSaveState('rest')
    }, 3000)
  }
}, autosaveInterval)

export function usePublishTraining() {
  const [, setSaveState] = useUiState('saveState')
  return (training, force) => {
    window.onbeforeunload = () => ''
    publishTraining(training, setSaveState, force)
  }
}
