import React, { useEffect, useRef, useState } from 'react'
import ColorSample from './ColorSample'
import ColorPicker from './ColorPicker'
import { updateStepProp } from 'features/contentEditor/contentEditorSlice'
import { useDispatch } from 'react-redux'
import { stringToHex } from 'ui/utils/hexConversions'
import useThumbnails from 'hooks/useThumbnails'

function useDebouncedEffect(fn, ms, deps, stop = false) {
  const timeoutId = useRef()
  useEffect(() => {
    if (stop) return
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(fn, ms)
  }, deps)
}

const ColorPickerBlock = ({ step }) => {
  const dispatch = useDispatch()
  const [setThumbnail] = useThumbnails()

  const colors = ['#f8f8f8', '#2D2D2D', '#FF6A6A', '#4C97FF', '#13D585']
  const stepHighlightColor = step.payload.highlightColor || ''
  const [pickerHidden, setPickerHidden] = useState(true)
  const [customColor, setCustomColor] = useState('#8A9BA8')
  const [selectedColor, setSelectedColor] = useState(undefined)

  useDebouncedEffect(
    () => {
      dispatch(
        updateStepProp(
          step.id,
          ['payload', 'highlightColor'],
          stringToHex(selectedColor),
          true,
        ),
      )
      setThumbnail(step.id)
    },
    500,
    [dispatch, step, updateStepProp, setThumbnail, selectedColor],
    !selectedColor ||
      stringToHex(selectedColor) === step.payload.highlightColor,
  )

  return (
    <div className="colorPicker">
      {pickerHidden ? null : (
        <ColorPicker
          customColor={customColor}
          onChangeFinished={value => {
            setCustomColor(value.hex)
            setSelectedColor(value.hex)
          }}
        />
      )}
      <div className="colorPicker-samples">
        {colors.map(color => (
          <ColorSample
            key={color}
            color={color}
            selectedColor={stepHighlightColor}
            onSelect={() => {
              setSelectedColor(color)
              setPickerHidden(true)
            }}
          />
        ))}
        <ColorSample
          className="custom-color"
          color={customColor}
          selectedColor={stepHighlightColor}
          onSelect={() => {
            setSelectedColor(customColor)
            setPickerHidden(!pickerHidden)
          }}
        />
      </div>
    </div>
  )
}

export default ColorPickerBlock
