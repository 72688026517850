import React from 'react'
import PropTypes from 'prop-types'
import { InputGroup } from '@blueprintjs/core'
import { useInputUpdater } from 'hooks/useInputUpdater'
import { updateAnnotationLabel } from 'features/contentEditor/contentEditorSlice'
import { Annotation } from 'domain/annotation'

const AnnotationLabel = ({ annotation, lang }) => {
  const annotationId = Annotation.getId(annotation)
  const label = Annotation.getLabel(annotation, lang)
  const field = useInputUpdater(label, v =>
    updateAnnotationLabel(annotationId, v, lang),
  )

  return (
    <>
      <InputGroup
        placeholder="Annotation label"
        value={field.value}
        onChange={field.onChange}
        onKeyPress={field.onKeyPress}
        onBlur={field.onBlur}
      />
    </>
  )
}

AnnotationLabel.propTypes = {
  lang: PropTypes.string,
  stepId: PropTypes.string,
  text: PropTypes.string,
}

export default AnnotationLabel
