const ENV = process.env['REACT_APP_ENV'] || process.env['NODE_ENV']

const environments = {
  development: {
    environment: 'development',
    consolePublish: true,
    autosave: false,
  },
  staging: {
    environment: 'staging',
    consolePublish: true,
    autosave: true,
  },
  production: {
    environment: 'production',
    consolePublish: false,
    autosave: true,
  },
}

export default environments[ENV] || environments.production
