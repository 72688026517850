import React, { useState, useCallback, useEffect } from 'react'
import StepCard from 'ui/components/thumbs-sidebar/StepCard'
import AddStepButton from './AddStepButton'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useUiState } from 'hooks/useUiState'
import { Step } from 'domain/step'
import ThumbsSidebar from '../thumbs-sidebar/ThumbsSidebar'
import { useActiveSelection } from 'hooks/useActiveSelection'
import { Training } from 'domain/training'
import Collapsable from '../collapsable/Collapsable'
import { DragDropContext } from 'react-beautiful-dnd'
import DroppableStepsContainer from './DroppableStepsContainer'
import { setAnnotationSteps } from 'features/contentEditor/contentEditorSlice'
import cx from 'classnames'

const AnnotationSidebar = ({
  training,
  selectedLanguage,
  startOpen = true,
}) => {
  const [activeSelection, setActiveSelection] = useActiveSelection()

  const [isOpen, setOpen] = useState(startOpen)
  const toggleOpen = useCallback(() => setOpen(!isOpen), [isOpen])
  const [associatedNodes] = useUiState('model-step-nodes')

  const explorationStep = Training.getExplorationStep(training)
  const annotations = Training.getAnnotations(training)
  const [ongoingDrag, setOngoingDrag] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!activeSelection.id) {
      setActiveSelection('default')
    }
  }, [activeSelection])

  // @TODO: removeo duplication with StepSidebar / The logic is different
  function handleOnDragEnd(result) {
    setOngoingDrag(false)
    if (!result.destination) return
    const isHeader = result.destination.droppableId.includes('header-')
    const destination = result.destination.droppableId.replace('header-', '')
    const newAnnotations = annotations
    const annotationOrigin = newAnnotations.find(
      a => a.id === result.source.droppableId,
    )
    const annotationDestination = newAnnotations.find(a => a.id === destination)

    if (annotationOrigin === annotationDestination) {
      const list = [...annotationOrigin.steps]
      const [movedStep] = list.splice(result.source.index, 1)
      list.splice(result.destination.index, 0, movedStep)

      dispatch(setAnnotationSteps(result.source.droppableId, list))
      return
    }
    const originList = [...annotationOrigin.steps]
    const destinationList = [...annotationDestination.steps]
    const [movedStep] = originList.splice(result.source.index, 1)
    const index = isHeader ? destinationList.length : result.destination.index
    destinationList.splice(index, 0, movedStep)

    dispatch(setAnnotationSteps(result.source.droppableId, originList))
    dispatch(setAnnotationSteps(destination, destinationList))
    setActiveSelection({
      ...activeSelection,
      parentId:
        result.draggableId === activeSelection.id
          ? destination
          : activeSelection.parentId,
    })
  }

  function onDragStart() {
    setOngoingDrag(true)
  }

  return (
    <ThumbsSidebar>
      <div
        className={`thumbs-sidebar-main ${
          ongoingDrag ? 'prevent-smooth-scroll' : ''
        }`}
      >
        {explorationStep && (
          <StepCard
            step={explorationStep}
            isActive={explorationStep.id === activeSelection.id}
            isOverview={true}
            missingNode={
              associatedNodes &&
              Step.getAssociatedNode(explorationStep) &&
              !associatedNodes.includes(Step.getAssociatedNode(explorationStep))
            }
          />
        )}
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={onDragStart}>
          {annotations.map((annotation, i) => (
            <Collapsable
              title={
                annotation.label[selectedLanguage] || 'Unlabeled annotation'
              }
              annotation={annotation}
              selectedLanguage={selectedLanguage}
              isSelected={activeSelection.id === annotation.id}
              onClick={() =>
                setActiveSelection({ type: 'annotation', id: annotation.id })
              }
              isOpen={isOpen}
              toggleOpen={toggleOpen}
              key={i}
              className={cx({
                'collapsable-empty': annotation.steps.length === 0,
              })}
            >
              <DroppableStepsContainer
                steps={annotation.steps}
                annotationId={annotation.id}
              ></DroppableStepsContainer>
            </Collapsable>
          ))}
        </DragDropContext>
        <p className="sidebar-hint">
          Double click into your model to add an annotation
        </p>
      </div>

      <AddStepButton
        disabled={annotations.length <= 0}
        explorationMode={Training.hasExploration(training)}
      />
    </ThumbsSidebar>
  )
}

StepCard.propTypes = {
  // @TODO
  // annotation: PropTypes.arrayOf(stepProps),
  isLoading: PropTypes.bool,
}

export default AnnotationSidebar
