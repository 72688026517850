import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Icon } from '@blueprintjs/core'
import DeleteStepButton from '../fields/DeleteStepButton'
import { identity } from 'lodash'
import { Droppable } from 'react-beautiful-dnd'
import { useActiveSelection } from 'hooks/useActiveSelection'

const Collapsable = ({
  children,
  title,
  className,
  isSelected,
  inactive,
  annotation,
  isStep,
  index,
  startOpen = true,
  onClick = identity,
}) => {
  const [activeSelection] = useActiveSelection()
  const [isOpen, setOpen] = useState(startOpen)
  const classes = cx('collapsable', {
    'is-open': isOpen,
    [className]: className,
    inactive: inactive,
    'collapsable-step': isStep,
    'is-selected': isSelected,
  })
  const toggleOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen])
  const onElementClick = useCallback(
    e => {
      e.preventDefault()
      onClick()
    },
    [onClick],
  )
  const noTextSelect = useCallback(e => e.preventDefault(), [])

  useEffect(() => {
    if (!isOpen && activeSelection.parentId === annotation.id) {
      setOpen(true)
    }
  }, [activeSelection, annotation])

  return (
    <div className={classes} onMouseDown={noTextSelect}>
      <Droppable
        droppableId={`header-${annotation.id}` || 'step-list'}
        isDropDisabled={isOpen}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="droppable-list"
          >
            <div
              className={`collapsable-header ${
                snapshot.isDraggingOver ? 'drag-over' : ''
              }`}
            >
              <h3
                className="collapsable-title"
                onMouseDown={noTextSelect}
                onClick={onElementClick}
              >
                {title}
              </h3>
              {isStep ? <DeleteStepButton stepIdx={index} /> : null}

              <div className="collapsable-chevron" onClick={toggleOpen}>
                <Icon icon="chevron-down" onMouseDown={noTextSelect} />
              </div>
            </div>
            <div className="drag-over-area"></div>
          </div>
        )}
      </Droppable>
      <div className="collapsable-content">{children}</div>
    </div>
  )
}

Collapsable.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  inactive: PropTypes.bool,
  bool: PropTypes.string,
  hint: PropTypes.string,
  isStep: PropTypes.bool,
}

export default Collapsable
